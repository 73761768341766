import React from 'react';
import { Helmet } from 'react-helmet';
import axios from '../utils/axios';
import Page from '../components/Page';
import { translate, getLanguage } from 'react-switch-lang';

class Imprint extends React.Component {

  state= { data: null };

  componentDidMount() {
    axios.get(`singletons/get/imprint`)
      .then(response => {
        this.setState({
          data: response.data
        });
      })
      .catch(error => this.setState({ error }));
  }

  render() {
    const { t, data } = this.props;
    const lang = getLanguage();

    return (
      <Page title={t('nav.imprint')}>
        <h1>{ t('nav.imprint') }</h1>
        <div dangerouslySetInnerHTML={{ __html: data[`text_${lang}`] ? data[`text_${lang}`] : data[`text`] }}></div>      
      </Page>
    );
  }
}

export default translate(Imprint);