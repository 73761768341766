import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../assets/YogabeeLogo.png';

// STYLES
const StyledLink = styled(Link)`
  img {
    height: 100px;
    width: auto;

    @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
      height: 144px;
    }
  }
`;

// COMPONENT
const Logo = props => (
  <StyledLink { ...props } to="/">
    <img src={ logo } alt="YogaBee Studio" />
  </StyledLink>
);

export default Logo;