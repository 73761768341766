import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Helmet } from 'react-helmet';
import Footer from './Footer';

// STYLES
const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.layout.width.content};
  padding: 120px 15px;
  padding: 120px 15px 350px 15px;
  min-height: 100vh;

  /* @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) { */
  @media only screen and (min-width: ${props => props.theme.bp.navigation}) {
    /* padding: 120px 15px; */
    padding: 180px 0 300px 0;
  }

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    padding: 180px 0 300px 0;
  }
`;

const StyledFooter = styled(Footer)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

// COMPONENT
const Page = props => (
  <React.Fragment>
    <Helmet><title>YogaBee - { props.title }</title></Helmet>
    <Content>
      <Container>
        <Row center="sm">
          <Col xs={12} sm={11} md={11}>{ props.children }</Col>
        </Row>
      </Container>
      <StyledFooter />
    </Content>
  </React.Fragment>
);

export default Page;