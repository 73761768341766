import React from 'react';
import Page from '../components/Page';
import { translate, getLanguage } from 'react-switch-lang';
import styled from 'styled-components';
import Slider from 'react-slick';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Teaser from '../components/Teaser';
import SliderArrow from '../components/SliderArrow';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import NewsItem from '../components/NewsItem';

// STYLES
const SliderItem = styled.div`
  padding: 0 1.5rem;
`;

const SliderContainer = styled.div`
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  margin-bottom: ${props => props.theme.layout.gap.large};
  position: relative;
`;

const StyledSliderArrowLeft = styled(SliderArrow)`
  left: 20px;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    left: -15px;
  }
`;

const StyledSliderArrowRight = styled(SliderArrow)`
  right: 20px;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    right: -15px;
  }
`;

const MasonryContainer = styled.div`
  margin: 0 -1.5rem;
`;

const BlogItem = styled.div`
  border-bottom: 3px solid black;
`;

const BlogTitle = styled.h3`
  line-height: 1.2;
  font-weight: normal;

  a {
    text-decoration: none;
  }
`;

const BlogDescription = styled.div`
  color: ${(props) => props.theme.greyMedium};

  ul {
    padding-left: 25px;
  }
`;

const BlogImage = styled.img`
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid ${(props) => props.theme.greyDark};
`;

// COMPONENT
class Ausbildung extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      images: [],
      current: '',
      currentIndex: null
    };
    this.sliderRef = React.createRef();
  }

  componentDidMount() {
    const images = this.props.data.gallery.map(img => img.path);

    this.setState({
      images: images,
      current: ''
    });
  }

  handleClickImage = (e, image) => {
    e && e.preventDefault();
    
    this.setState({
      current: image,
      currentIndex: this.props.data.gallery.findIndex(img => img.path === image)  
    })
  }

  handleCloseModal = (e) => {
    e && e.preventDefault();
 
    this.setState({
      current: ''
    })
  }
  
  handlePrev = () => {
    this.sliderRef.current.slickPrev();
  }
  
  handleNext = () => {
    this.sliderRef.current.slickNext();
  }

  render() {
    const { t } = this.props;
    const lang = getLanguage();
    const { images, current, currentIndex } = this.state;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerPadding: '50px',
      arrows: false,
      responsive: [
        { breakpoint: 600, settings: { slidesToShow: 1 } },
        { breakpoint: 800, settings: { slidesToShow: 3 } }
      ]
    };

    let blog = this.props.data[`blog_${lang}`] ? this.props.data[`blog_${lang}`] : this.props.data[`blog`];

    return (
      <Page title={ t('nav.trainings')}>
        <h1>{ t('nav.trainings_headline')}</h1>
        <Teaser className="teaser" dangerouslySetInnerHTML={{ __html: this.props.data[`intro_${lang}`] ? this.props.data[`intro_${lang}`] : this.props.data[`intro`] }}></Teaser>

        <SliderContainer>
          <Slider { ... settings } ref={ this.sliderRef }>
            {images.map(image => (
              <SliderItem>
                <img 
                  className="fluid" 
                  src={`${ process.env.REACT_APP_ROOT }/${ image }`} 
                  alt="" 
                  onClick={ e => this.handleClickImage(e, image)} 
                />
              </SliderItem>  
            ))}
          </Slider>
          <StyledSliderArrowLeft direction="left" prev={this.handlePrev} next={this.handleNext} />
          <StyledSliderArrowRight direction="right" prev={this.handlePrev} next={this.handleNext} />
        </SliderContainer>

        <h2>{ this.props.data[`headline_${lang}`] ? this.props.data[`headline_${lang}`] : this.props.data[`headline`] }</h2>
        <div dangerouslySetInnerHTML={{ __html: this.props.data[`text_${lang}`] ? this.props.data[`text_${lang}`] : this.props.data[`text`] }}></div>

        <MasonryContainer>
          <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 550: 2, 900: 2}}>
            <Masonry gutter={15}>
              {blog.map((item, index) => {
                return (
                  <BlogItem>
                    <BlogImage
                      src={`${process.env.REACT_APP_ROOT}/${item.value.image.path}`}
                      alt=""
                    />
                    <BlogTitle>{ item.value.headline }</BlogTitle>
                    <BlogDescription dangerouslySetInnerHTML={{ __html: item.value.description }}></BlogDescription>
                  </BlogItem>
                );
              })}
              {/* {filteredNews.map((item, index) => {
                let teacher = '';
                if (item.teacher) {
                  teacher = item.teacher.name;
                } else if (item.teacher_custom) {
                  teacher = item.teacher_custom;
                }

                return (
                  <NewsItem 
                    key = { index }
                    image = { item.image.path } 
                    title = { item[`title_${lang}`] ? item[`title_${lang}`] : item[`title`] }
                    type = { t(item.type) } 
                    headline = { item[`headline_${lang}`] ? item[`headline_${lang}`] : item[`headline`] }
                    teacher = { teacher }
                    date = { item[`date_${lang}`] ? item[`date_${lang}`] : item[`date`] }
                    time = { item[`time_${lang}`] ? item[`time_${lang}`] : item[`time`] }
                    teaser = { item[`teaser_${lang}`] ? item[`teaser_${lang}`] : item[`teaser`] }
                    tags = { item[`tags_${lang}`] ? item[`tags_${lang}`] : item[`tags`] }
                    link = { item[`title_slug_${lang}`] ? item[`title_slug_${lang}`] : item[`title_slug`] }
                    mbo_link = {item.mbo_link || null} />
                );
              })} */}
            </Masonry>
          </ResponsiveMasonry>
        </MasonryContainer>
        
        { current &&
          <Lightbox
            mainSrc={`${ process.env.REACT_APP_ROOT }/${current}`}
            nextSrc={(currentIndex + 1 >= images.length) ? `${ process.env.REACT_APP_ROOT }/${images[0]}` : `${ process.env.REACT_APP_ROOT }/${images[currentIndex + 1]}`}
            prevSrc={(currentIndex - 1 < 0) ? `${ process.env.REACT_APP_ROOT }/${images[images.length - 1]}` : `${ process.env.REACT_APP_ROOT }/${images[currentIndex - 1]}`}
            onMovePrevRequest={() =>
              this.setState({
                currentIndex: (currentIndex - 1) <= 0 ? images.length - 1 : currentIndex - 1,
                current: (currentIndex - 1) <= 0 ? images[images.length - 1] : images[currentIndex - 1]
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                currentIndex: (currentIndex + 1) >= images.length ? 0 : currentIndex + 1,
                current: (currentIndex + 1) >= images.length ? images[0] : images[currentIndex + 1]
              })
            } 
            onCloseRequest={this.handleCloseModal}
          />
        }
      </Page>
    );
  }
}

export default translate(Ausbildung);