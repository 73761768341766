import React from 'react';

const ToggleIcon = props => {

  let icon = (
    <div>
      <svg width="26px" height="26px" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
        <g id="Group" transform="translate(1.113281, 0.500000)" stroke="#B0BB00" stroke-width="3">
          <path d="M0.88671875,12.0839844 L22.9667969,12.0839844" id="Line-3"></path>
          <path d="M0,12 L22.0800781,12" id="Line-3" transform="translate(11.500000, 12.000000) rotate(-90.000000) translate(-11.500000, -12.000000) "></path>
        </g>
      </svg>
    </div>
  );

  if (props.isOpen) {
    icon = (
      <div>
        <svg width="26px" height="26px" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
          <g id="Group" transform="translate(1.113281, 0.500000)" stroke-width="3">
            {/* <path d="M0,12 L22.0800781,12" id="Line-3" stroke="#FFFFFF" transform="translate(11.500000, 12.000000) rotate(-90.000000) translate(-11.500000, -12.000000) "></path> */}
            <path d="M0.88671875,12.0839844 L22.9667969,12.0839844" id="Line-3" stroke="#B0BB00"></path>
          </g>
        </svg>
      </div>
    );
  }

  return icon;
}

export default ToggleIcon;