import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { translate } from 'react-switch-lang';

// STYLES
const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavItem = styled.li`
  display: inline-block;
  margin-right: 20px;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;

  &:hover,
  &.active {
    text-decoration: inherit;
    font-weight: normal;
  }
`;

// COMPONENT
const FooterNavigation = props => {

  const { t } = props;
  
  return (
    <nav { ...props }>
      <NavList>
        <NavItem><StyledNavLink to="/">{ t('nav.home') }</StyledNavLink></NavItem>      
        <NavItem><StyledNavLink to="/contact">{ t('nav.contact') }</StyledNavLink></NavItem>      
        <NavItem><StyledNavLink to="/agb">{ t('nav.agb') }</StyledNavLink></NavItem>      
        <NavItem><StyledNavLink to="/imprint">{ t('nav.imprint') }</StyledNavLink></NavItem>      
        <NavItem><StyledNavLink to="/privacy">{ t('nav.privacy') }</StyledNavLink></NavItem>      
      </NavList>
    </nav>
  );
};

export default translate(FooterNavigation);