import React from 'react';
import styled, { css } from 'styled-components';
import isExternal from 'is-url-external';
import { Link } from 'react-router-dom';

// STYLES
const Surface = styled.div`
  display: inline-block;

  ${props => props.schedule && css`
    max-width: 110px;
    width: 100%;

    & > * {
      width: 100%;
      text-align: center;      
    }
  `}
  
  & > * {
    font-family: ${props => props.theme.font.family.primary};
    background-color: ${props => props.theme.color.button.surface};
    color: ${props => props.theme.color.button.inlineDark};
    border-radius: 3px;
    text-decoration: none;
    padding: 0 15px;
    font-weight: normal;
    display: inline-block;
    border: none;
    height: 32px;
    line-height: 32px;
    vertical-align: middle;

    ${props => props.disabled && css`
      background-color: #aaa;
      padding: 0 15px;
      display: inline-block;
      height: 32px;
      line-height: 32px;
      border-radius: 3px;
      font-weight: normal;
    `}

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: ${props => props.theme.color.button.surfaceHover};
      text-decoration: inherit;

      ${props => props.disabled && css`
        background-color: #aaa;
      `}
    }
  }

  ${props => props.large && css`
    & > * {
      height: 60px;
      line-height: 60px;
      text-transform: uppercase;
    }
  `}
`;

// COMPONENT
const Button = props => {
  
  let result = null;

  if (props.disabled) {
    result = <button disabled>{ props.children }</button>
  } else if (props.href === '#') {
    result = <button { ...props }>{ props.children }</button>;
  } else {
    result = isExternal(props.href) ? <a href={ props.href } target="_blank" rel="noopener noreferrer">{ props.children }</a> : <Link to={ props.href }>{ props.children }</Link>;
  }

  return (
    <Surface { ...props }>
      { result }
    </Surface>
  );
};

export default Button;