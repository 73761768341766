import React from 'react';
import { translate } from 'react-switch-lang';
import styled from 'styled-components';
import Page from '../components/Page';

// STYLES
const Headline = styled.h1`
  text-align: center;
`;

// COMPONENT
const NotFound = props => {
  const { t } = props;

  return (
    <Page title="404">
      <Headline>404 - { t('notFound') }</Headline>
    </Page>
  );
};

export default translate(NotFound);