import React from 'react';
import styled from 'styled-components';

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  clear: both;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Video = props => {
  return (
    <VideoContainer>
      <iframe id="ytplayer" type="text/html" width="100%" height="600"
        src={`https://www.youtube.com/embed/${props.youtubeId}`}
        frameBorder="0" title="yogabee" />
    </VideoContainer>
  );
};

export default Video;