import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { translate, getLanguage } from 'react-switch-lang';
import Schedule from '../components/Schedule';
import PrevNext from '../components/PrevNext';
import { Col, Row } from 'react-styled-flexboxgrid';
import Page from '../components/Page';
import FigCaption from '../components/FigCaption';
import Button from '../components/Button';

const ScheduleLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizeSmall};

  &:hover {
    text-decoration: none;
  }
`;

const ScheduleTitle = styled.h4`
  font-size: 2.4rem;
  font-weight: lighter;
`;

const StyledButton = styled(Button)`
  text-align: center;
  display: block;
  margin: 5rem 0 0 0;
`;

class InfoTeacher extends React.Component {

  state = {
    teachers: null,
    currentIndex: null,
    currentTeacher: null
  }

  componentWillMount() {
    const teachers = this.props.teachers.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      
      return 0;
    });

    const index = this.props.teachers.findIndex(elem => elem.name_slug === this.props.match.params.slug);
    
    this.setState({
      teachers: teachers,
      currentIndex: index,
      currentTeacher: this.props.teachers[index]
    });
  }

  prevHandler = () => {
    const index = (this.state.currentIndex > 0) ? this.state.currentIndex - 1 : this.state.teachers.length - 1;

    this.setState(prevState => {
      return {
        currentIndex: index,
        currentTeacher: prevState.teachers[index]
      }
    });
  };

  nextHandler = () => {
    const index = (this.state.currentIndex === this.state.teachers.length - 1) ? 0 : this.state.currentIndex + 1

    this.setState(prevState => {
      return {
        currentIndex: index,
        currentTeacher: prevState.teachers[index]
      }
    })
  };

  render() {
    const { t, data } = this.props;
    const { currentTeacher } = this.state;

    const prevTeacher = this.state.currentIndex === 0 
      ? this.state.teachers[this.state.teachers.length - 1].name
      : this.state.teachers[this.state.currentIndex - 1].name;
    
    const nextTeacher = this.state.currentIndex === this.state.teachers.length - 1
      ? this.state.teachers[0].name
      : this.state.teachers[this.state.currentIndex + 1].name;

    const lang = getLanguage();

    console.log(currentTeacher.name);

    return (
      <Page title={currentTeacher.name}>
        {/* <h1>{ data[`headline_${lang}`] ? data[`headline_${lang}`] : data[`headline`] }</h1>
        <div className="teaser" dangerouslySetInnerHTML={{ __html: data[`intro_${lang}`] ? data[`intro_${lang}`] : data[`intro`] }}></div> */}
        {/* <h1>Der Yogabee-Stundenplan</h1>
        <p className="teaser">In unserem Stundenplan findet jeder die passende Yoga-Praxis – in einer offenen Klasse oder einem festen Kurs. Bis auf „Yoga für Schwangere“ und „Mama & Baby-Yoga“ sind unsere Angebote für Frauen und Männer gleichermaßen geeignet. Vorkenntnisse sind – bis auf die „Ashtanga Mysore“-Stunden – nicht notwendig.</p> */}
        
        <PrevNext actualTitle={this.state.currentTeacher.name} prev={this.prevHandler} next={this.nextHandler} prevTitle={prevTeacher} nextTitle={nextTeacher} />
        
        <Row className="mb-section">
          <Col xs={12} sm={3} md={3}>
            <FigCaption imgSrc={`${process.env.REACT_APP_ROOT}/${currentTeacher.image.path}`} imgAlt={currentTeacher.name} caption={currentTeacher.name} />
          </Col>
          <Col xs={12} sm={9} md={6}>
            {/* <div dangerouslySetInnerHTML={{ __html: this.state.currentTeacher.description_en }}></div> */}
            <div dangerouslySetInnerHTML={{ __html: this.state.currentTeacher[`description_${lang}`] ? this.state.currentTeacher[`description_${lang}`] : this.state.currentTeacher[`description`] }}></div>
          </Col>
        </Row>

        {/* <Row>
          <Col xs={12} md={9}><ScheduleTitle>{currentTeacher.name} {t('at_yogabee')}</ScheduleTitle></Col>
        </Row>
        
        <Schedule className="mb-section" filter="teacher" by={currentTeacher.name} data={this.props.schedules} layoutType="teacher" />
        <StyledButton large href="/offer/schedule">{t('complete_schedule')}</StyledButton> */}
      </Page>
    );
  }
}

export default translate(InfoTeacher);