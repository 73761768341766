import React from 'react';
import styled from 'styled-components';
import { translate } from 'react-switch-lang';
import FooterNavigation from './FooterNavigation';
import SocialActions from './SocialActions';
import LogoSVG from './LogoSVG';
import Modal from 'react-responsive-modal';
import Button from './Button';

// STYLES
const FooterAddressSurface = styled.div`
  background-color: ${props => props.theme.color.surface.medium};
  color: white;
  font-weight: normal;
  text-align: center;
  padding: 35px 0;
  position: relative;

  address {
    margin: 0;
    width: 100%;
  }
`;

const FooterActionSurface = styled.div`
  padding: 35px 0;
  background-color: ${props => props.theme.color.primary100};
`;

const FooterContainer = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.layout.width.container};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0 15px;
  position: relative;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    padding: 0 30px;
  }

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    flex-direction: row;
    padding: 0 15px;
  }
`;

const StyledFooterNavigation = styled(FooterNavigation)`
  margin-bottom: 20px;
`;

const StyledLogoSVG = styled(LogoSVG)`
  width: 80px;
  position: absolute;
  top: -55px;
  left: 15px;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    /* left: 30px; */
  }

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    width: 110px;
    left: 0;
    top: -58px;
  }
`;

const modalStyles = {
  modal: {
    maxWidth: '500px',
    width: '90%',
    border: '2px solid #B0BB00',
    paddingBottom: '20px'
  },
  closeButton: {
    cursor: 'pointer',
    outline: 'none'
  }
};

const Form = styled.form`
  label {
    display: block;
    margin: 5px 0;
  }

  input[type=email] {
    width: 100%;
    display: block;
    margin-bottom: 30px;
  }

  input[type=submit] {
    background: none;
    border: 1px solid ${props => props.theme.greyDark};
    cursor: pointer;
  }
`;

const NewsletterInput = styled.input`
  &:focus {
    outline: none;
  }
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const Btn = styled.button`
font-family: ${props => props.theme.font.family.primary};
    background-color: ${props => props.theme.color.button.surface};
    color: ${props => props.theme.color.button.inlineDark};
    border-radius: 3px;
    text-decoration: none;
    padding: 0 15px;
    font-weight: normal;
    display: inline-block;
    border: none;
    height: 32px;
    line-height: 32px;
    vertical-align: middle;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: ${props => props.theme.color.button.surfaceHover};
      text-decoration: inherit;
    }
  `;

// COMPONENT
class Footer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { modalOpen: false };
  }

  openModalHandler = () => {
    this.setState({ modalOpen: true });
  };
  
  closeModalHandler = () => {
    this.setState({ modalOpen: false });
  }

  render() {
    const { t } = this.props;

    return (
      <div { ...this.props }>
        <FooterAddressSurface>
          <FooterContainer>
            <StyledLogoSVG />
            <address>{ t('address') }, <NoWrap>{ t('phone') }</NoWrap>, <a href="mailto:info@yogabee.de">info@yogabee.de</a></address>
          </FooterContainer>
        </FooterAddressSurface>
        <FooterActionSurface>
          <FooterContainer>
            <StyledFooterNavigation />
            <SocialActions click={ this.openModalHandler } />
          </FooterContainer>
        </FooterActionSurface>

        <Modal 
          styles={modalStyles}
          open={this.state.modalOpen} 
          onClose={this.closeModalHandler} 
          center
        >
          <div>
            <p><strong>YogaBee Newsletter</strong></p>
            <Form action="https://yogabee.us11.list-manage.com/subscribe/post?u=e29f80fba5952709ce0bf1ccf&amp;id=871ba50e36" method="post" name="mc-embedded-subscribe-form" target="_blank">
              <div>
                <label for="mce-EMAIL">{ t('your_email') }</label> 
                <NewsletterInput id="mce-EMAIL" name="EMAIL" type="email" defaultValue="" />
              </div>
              <Btn name="subscribe" type="submit" onClick={this.closeModalHandler}>{ t('subscribe_free') }</Btn>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}

export default translate(Footer);