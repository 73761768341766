const Sitemap = [
  {
    label: 'nav.about',
    link: '/about',
    alias: 'about',
    children: [
      {
        label: 'nav.philosophy',
        link: '/about/philosophy',
        alias: 'philosophy'
      },
      {
        label: 'nav.team',
        link: '/about/team',
        alias: 'team'
      },
      {
        label: 'nav.studio',
        link: '/about/studio',
        alias: 'studio'
      },
      {
        label: 'nav.faq',
        link: '/about/faq',
        alias: 'faq'
      }
    ]
  },
  {
    label: 'nav.offer',
    link: '/offer',
    alias: 'offer',
    children: [
      {
        label: 'nav.schedule',
        link: '/offer/schedule',
        alias: 'schedule'
      },
      {
        label: 'nav.prices',
        link: '/offer/prices',
        alias: 'price'
      },
      {
        label: 'nav.customYoga',
        link: '/offer/custom-yoga',
        alias: 'custom-yoga'
      }
    ]
  },
  {
    label: 'nav.news',
    link: '/news',
    alias: 'news',
    children: [
      {
        label: 'nav.workshops',
        link: '/news/workshops',
        alias: 'workshop'
      },
      {
        label: 'nav.events',
        link: '/news/events',
        alias: 'events'
      },
      {
        label: 'nav.misc',
        link: '/news/misc',
        alias: 'misc'
      }
    ]
  },
  {
    label: 'nav.trainings',
    link: '/ausbildung',
    alias: 'ausbildung',
    children: []
  }
];

export default Sitemap;