import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Navigation from './Navigation';
import Language from './Language';
import SubNavigation from './SubNavigation';
import Logo from './Logo';
import MobileToggle from './MobileToggle';
import MobileNavigation from './MobileNavigation';

// STYLES
const HeaderSurface = styled.header`
  background-color: ${props => props.theme.color.surface.medium};
  z-index: 100;
  position: fixed;
  left: 0;
  right: 0;

  /* @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) { */
  @media only screen and (min-width: ${props => props.theme.bp.navigation}) {
    position: relative;
  }
`;

const HeaderContainer = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.layout.width.container};
  display: flex;
  justify-content: space-between;
  height: 70px;
  padding: 0 15px;
`;

const StyledNavigation = styled(Navigation)`
  padding-top: 20px;
  flex: 1;
  display: none;

  /* @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) { */
  @media only screen and (min-width: ${props => props.theme.bp.navigation}) {
    display: flex;
  }
`;

const StyledSubNavigation = styled(SubNavigation)`
  display: none;

  /* @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) { */
  @media only screen and (min-width: ${props => props.theme.bp.navigation}) {
    display: flex;
  }
`;

const StyledLogo = styled(Logo)`
  margin-right: 20px;
`;

const StyledMobileToggle = styled(MobileToggle)`
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-25%, -25%);

  /* @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) { */
  @media only screen and (min-width: ${props => props.theme.bp.navigation}) {
    display: none;
  }
`;

//COMPONENT
class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPath: this.props.location.pathname,
      hoverPath: null,
      lastPath: null,
      showMobileNav: false
    };
  }
  
  enterHandler = link => {
    // this.setState({ 
    //   hoverPath: link,
    //   lastPath: link
    // });
  }

  leaveHandler = () => {
    //this.setState({ hoverPath: null });
  }

  enterFlyoutHandler = () => {
    this.setState(prevState => ({
      hoverPath: prevState.lastPath
    }));
  }

  leaveFlyoutHandler = () => {
    this.setState(prevState => ({
      hoverPath: null,
      lastPath: null
    }));
  }

  mobileToggleHandler = () => {
    this.setState(state => ({ showMobileNav: !state.showMobileNav }));
  };

  mobileHideHandler = () => {
    this.setState({ showMobileNav: false });
  };

  render() {

    return (
      <React.Fragment>
        <HeaderSurface>
          <HeaderContainer>
            <StyledLogo onClick={ this.mobileHideHandler } />
            <StyledNavigation 
              enter={ this.enterHandler } 
              leave={ this.leaveHandler } 
            />
            <StyledMobileToggle { ...this.props } toggle={ this.mobileToggleHandler } icon={this.state.showMobileNav} />
            <Language />
          </HeaderContainer>
        </HeaderSurface>
        <StyledSubNavigation 
          path={ this.props.location.pathname } 
          hover={ this.state.hoverPath } 
          enter={ this.enterFlyoutHandler }
          leave={ this.leaveFlyoutHandler }
        />

        <MobileNavigation show={ this.state.showMobileNav } toggle={ this.mobileToggleHandler } />
      </React.Fragment>
    );
  }
}

export default withRouter(Header);