import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import sitemap from './Sitemap';

// STYLES
const NavContainer = styled.div`
  background-color: ${props => props.theme.color.surface.medium};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  text-align: center;
  overflow: scroll;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    display: none;
  }
`;

const Nav = styled.nav`
  padding: 120px 0 0 10px;
  width: 80%;
  margin: 0 auto;
  text-align: left;

  ul {
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;
  }
`;

const MobileNavLink = styled(NavLink)`
  text-decoration: none;
  font-size: 1.8rem;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }

  &.active {
    font-weight: bold;
  }
`;

const NavItem = styled.li`
  color: ${props => props.theme.greyDark};
  border-bottom: 2px solid ${props => props.theme.greyDark};
  padding-bottom: 2px;
  margin-top: 8px;
`;

const NavItemTop = styled(NavItem)`
  color: white;
  border-color: white;
  margin-top: 20px;
`;

// COMPONENT
const  MobileNavigation = props => {

  const { t } = props;
  let result = null;
  let subNavigation = [];

  sitemap.forEach(page => {
    subNavigation.push(<NavItemTop onClick={ props.toggle }><MobileNavLink to={ page.link }>{ t(page.label) }</MobileNavLink></NavItemTop>);

    page.children.forEach(subPage => {
      subNavigation.push(<NavItem onClick={ props.toggle }><MobileNavLink to={ subPage.link }>{ t(subPage.label) }</MobileNavLink></NavItem>);
    });
  });

  if (props.show) {
    result = (
      <NavContainer>
        <Nav>
          <ul>
            { subNavigation }
          </ul>
        </Nav>
      </NavContainer>
    );
  }
  return (
    <div>{result}</div>
  );
};

export default translate(MobileNavigation);

