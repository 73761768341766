import React from 'react';
import styled from 'styled-components';

// STYLES
const TeaserContent = styled.div`
  font-size: 2.4rem;
  line-height: 3.4rem;
  margin-bottom: 6.5rem;
`;

// COMPONENT
const Teaser = props => (
  <TeaserContent { ...props }>{ props.children }</TeaserContent>
);

export default Teaser;