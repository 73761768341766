import React from 'react';
import { Link } from 'react-router-dom';

const LogoSVG = props => (
  <Link { ...props } to="/">
    <svg viewBox="0 0 304 180" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Yogabee-Bee" fillRule="nonzero">
        <path d="M90.5,180 C140.474478,180 181,139.674985 181,89.9477655 C181,40.2205456 140.36949,0 90.5,0 C40.6305104,0 0,40.3250145 0,89.9477655 L0,89.9477655 C0,139.674985 40.525522,180 90.5,180" id="Path" fill="#B0BC00"></path>
        <path d="M301.751681,22.3232539 C292.516786,-16.6566847 202.791387,56.5878774 188.204451,68.1142033 C189.778581,52.7108405 177.500368,46.1093993 169.629719,47.3668166 C161.654128,48.624234 153.153827,62.4558251 158.610811,73.6677967 C145.178236,68.3237729 31.4211223,24.20938 34.044672,65.1802295 C36.8781056,108.246775 61.7493566,97.8730812 55.9775473,104.474522 C47.1624204,114.324292 46.9525364,127.631959 66.68163,147.017144 C95.0159665,174.785111 151.68464,97.6635116 161.129418,83.7271357 C164.802388,93.3673356 173.827399,101.645333 178.65473,101.016625 C184.111714,100.178346 188.519277,89.804653 189.148929,79.6405293 C199.74807,88.3376661 275.306301,145.654941 294.615626,110.132901 C307.838317,85.8228314 303.640637,73.1438728 292.411844,66.3328621 C284.856021,61.7223317 311.72117,64.3419512 301.751681,22.3232539" id="Path" fill="#FFE37D"></path>
        <path d="M145.935381,80.0041605 C145.935381,80.0041605 114.649737,99.9274746 92.6765429,102.024666 C80.3297003,103.178121 46.3235658,107.477362 48.2069825,68.050172 C49.9857649,30.3007346 149.911483,71.7202562 151.690265,72.5591325 C153.469048,73.3980089 154.410756,71.7202562 152.631974,70.357082 C150.853191,68.9939079 42.2428297,27.888965 44.0216121,66.4772787 C45.8003945,105.065592 71.3311539,106.743345 93.1997142,104.331576 C115.068274,101.919806 145.935381,83.2548065 147.818798,82.206211 C149.702214,81.1576155 149.59758,77.8021099 145.726113,77.3826718 C141.854645,76.9632336 105.965094,77.9069695 86.6077558,86.610312 C63.9021215,96.7816882 42.766001,111.042587 72.168228,139.354665 C99.6870383,165.88413 157.235881,90.3852557 157.863687,88.8123625 C158.491492,87.2394693 156.817344,84.8276997 155.561733,86.1908738 C152.841242,89.2318007 96.6526448,161.480029 73.2145706,135.370002 C47.7884455,107.057924 69.5523715,97.515705 87.863367,88.707503 C105.546557,80.2138796 145.935381,80.0041605 145.935381,80.0041605" id="Path" fill="#4A2440"></path>
        <path d="M201.844114,71.3873571 C201.844114,71.3873571 238.141908,79.9980932 259.710163,74.8526533 C271.809428,71.912402 305.582158,64.8768006 290.95783,28.1236591 C276.964767,-7.05434779 195.426243,64.8768006 194.0585,66.2419173 C192.690757,67.607034 191.217803,66.3469263 192.480335,64.4567647 C193.742867,62.5666032 283.382638,-11.7797517 294.429792,25.3934257 C305.476947,62.5666032 281.594051,72.4374469 259.920585,77.2678598 C238.247119,82.0982727 203.001435,74.5376264 200.792004,74.1175905 C198.582573,73.6975546 197.635674,70.5472853 201.212848,68.8671417 C204.790022,67.1869981 239.194018,56.3710736 260.44664,58.2612352 C285.381647,60.4664237 310.106231,67.0819891 291.483885,103.520104 C274.018859,137.648021 194.584555,84.933515 193.532445,83.6734073 C192.480335,82.4132996 193.216812,79.4730483 194.794977,80.4181291 C198.372151,82.4132996 275.386602,132.397572 289.169243,100.054808 C304.109204,64.9818096 280.331519,62.9866391 260.025796,60.6764416 C240.351339,58.4712531 201.844114,71.3873571 201.844114,71.3873571" id="Path" fill="#4A2440"></path>
        <path d="M169.939551,72 C165.527928,72 161.218902,70.8398851 157.935834,68.7305853 C157.012471,68.0977954 156.704683,66.8322155 157.320259,65.8830305 C157.935834,64.9338456 159.166984,64.6174507 160.090347,65.2502406 C167.066866,69.7852352 179.275775,68.8360503 185.328932,60.820711 C186.047103,59.8715261 187.278253,59.7660611 188.201616,60.398851 C189.124979,61.137106 189.227575,62.4026859 188.611999,63.3518708 C184.097781,69.1524453 176.813474,72 169.939551,72 Z" id="Path" fill="#4A2440"></path>
        <path d="M175.739741,96 C172.431139,96 169.22593,95.1351351 166.227509,94.1621622 C165.296964,93.8378378 164.779995,92.7567568 165.090177,91.7837838 C165.400358,90.8108108 166.434296,90.2702703 167.364841,90.5945946 C175.946529,93.5135135 181.11622,92.6486486 185.872337,87.5675676 C186.596093,86.8108108 187.733426,86.8108108 188.457182,87.5675676 C189.180939,88.3243243 189.180939,89.5135135 188.457182,90.2702703 C184.424823,94.4864865 180.082282,96 175.739741,96 Z" id="Path" fill="#4A2440"></path>
        <path d="M169.144562,60 C166.317091,60 163.280179,59.4615385 160.243267,58.3846154 C159.300776,58.0615385 158.777171,56.9846154 159.091334,56.0153846 C159.405498,55.0461538 160.452709,54.5076923 161.395199,54.8307692 C169.458725,57.7384615 176.684482,56.2307692 180.768606,50.7384615 C181.396932,49.8769231 182.548865,49.7692308 183.281912,50.4153846 C184.119681,51.0615385 184.224402,52.2461538 183.596076,53 C180.349721,57.5230769 175.113665,60 169.144562,60 Z" id="Path" fill="#4A2440"></path>
        <path d="M171.707805,83 C168.017297,83 164.019247,82.1593518 160.226225,80.4780554 C159.201084,80.0577313 158.688513,78.796759 159.201084,77.7459487 C159.61114,76.6951385 160.84131,76.1697334 161.866451,76.6951385 C172.83546,81.5288656 181.139103,77.8510298 185.444695,72.7020595 C186.162294,71.8614113 187.494978,71.7563303 188.31509,72.4918975 C189.135203,73.2274647 189.237717,74.593518 188.520119,75.4341662 C184.419554,80.3729744 178.371222,83 171.707805,83 Z" id="Path" fill="#4A2440"></path>
      </g>
    </svg>
  </Link>
);

export default LogoSVG;