import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import sitemap from './Sitemap';

// STYLES
const NavSurface = styled.div`
  box-shadow: 0px 3px 13px 0px rgba(164, 145, 160, 1);
  z-index: 90;
  position: relative;
  background-color: white;
`;

const NavContainer = styled.div`
  height: 74px;
  margin: 0 auto;
  max-width: ${props => props.theme.layout.width.container};
  width: 100%;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 0 50px 0 185px;
  align-items: flex-start;
  margin: 0 0 0 -1rem;
  
  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    padding: 0 50px 0 251px;
  }

  & > * {
    flex: 1;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-transform: uppercase;
  font-weight: normal;
  text-decoration: none;
  display: block;
  margin: 0 1rem;
  padding-top: 20px;
  /* font-size: 1.3rem; */

  &:hover,
  &:active,
  &.active {
    font-weight: bold;
    text-decoration: none;
    padding-top: 19px;
    
    span {
      border-top: 2px solid ${props => props.theme.color.text.height};
    }
  }
`;

const NavLabel = styled.span`
  display: inherit;
  padding-top: 5px;
  border-top: 1px solid ${props => props.theme.color.text.height};
`;

const Placeholder = styled.div`
  margin: 0 1rem;
`;

// COMPONENT
const SubNavigation = props => {
  
  let alias = props.path.split('/')[1];
  
  if (props.hover) {
    alias = props.hover.split('/')[1];
  }

  const { t } = props;
  const match = sitemap.find(page => page.alias === alias);
  
  if (match && match.children) {
    return (
      <NavSurface onMouseEnter={ props.enter } onMouseLeave={ props.leave } { ...props }>
        <NavContainer>
          <Nav { ...props }>
            {match.children.map(page => (
              <StyledNavLink to={ page.link } key={ page.alias }><NavLabel>{ t(page.label) }</NavLabel></StyledNavLink>
            ))}

            {match.children.length < 4 ? <Placeholder>&nbsp;</Placeholder> : null}
          </Nav>
        </NavContainer>
      </NavSurface>
    );
  } 

  return null;
};

export default translate(SubNavigation);