import React from 'react';
import styled from 'styled-components';
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  getLanguage,
  translate
} from 'react-switch-lang';
import en from '../lang/en.json';
import de from '../lang/de.json';

// STYLES
const  ButtonContainer = styled.div`
  padding-top: 20px;
  width: 50px;
`;

const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:first-child {
    margin-right: 10px;
  }

  &:focus {
    outline: none;
  }

  &.active {
    font-weight: bold;
  }
`;


// COMPONENT
setTranslations({ de, en });
setDefaultLanguage('de');

setLanguageCookie('yogabee-lang');

const Language = props => {
  
  const handleSetLanguage = key => {
    setLanguage(key);
  };

  return (
    <ButtonContainer>
      <Button onClick={ e => handleSetLanguage('de') } className={getLanguage() === 'de' ? 'active' : null}>DE</Button>
      <Button onClick={ e => handleSetLanguage('en') } className={getLanguage() === 'en' ? 'active' : null}>EN</Button>
    </ButtonContainer>
  );
};

export default translate(Language);
