import { createGlobalStyle } from 'styled-components';
import RobotoNormal300 from '../fonts/roboto_normal_300.woff2';
import RobotoNormal400 from '../fonts/roboto_normal_400.woff2';
import RobotoNormal700 from '../fonts/roboto_normal_700.woff2';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(${RobotoNormal300}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(${RobotoNormal400}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(${RobotoNormal700}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  html, body {
    font-size: 58%;

    @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
      font-size: 62.5%;
    }
  }

  body {
    font-family: ${props => props.theme.font.family.primary};
    font-size: ${props => props.theme.font.size.default};
    background-color: ${props => props.theme.color.surface.body};
    color: ${props => props.theme.color.text.body};
  }

  h1 {
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.h1};
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: ${props => props.theme.layout.gap.h1};
  }

  h2 {
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.h2};
    // text-transform: uppercase;
    font-weight: normal;
    margin-bottom: ${props => props.theme.layout.gap.h2};
  }

  h3 {
    font-size: ${props => props.theme.font.size.h3};
    font-weight: inherit;
    line-height: inherit;
  }

  p {
    margin-bottom: ${props => props.theme.layout.gap.default};
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: inherit;
    }
  }

  img {
    width: 100%;

    &.static {
      width: auto;
    }
  }

  /* PAGE TRANSITION */
  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity ${props => props.theme.fx.speed} linear;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity ${props => props.theme.fx.speed} linear;
  }

  /* LIGHTBOX */
  .ril__outer {
    background-color: rgba(255, 255, 255, 0.9) !important;
  }

  .ril__inner {
    img {
      width: auto;
    }
  }

  /* SLICK SLIDER */
  .slick-dots {
    bottom: 0;
    position: static;
    margin: ${props => props.theme.layout.gap.default} 0 ${props => props.theme.layout.gap.small} 0;

    li {
      margin: 0 3px;

      button::before {
        font-size: 12px;
      }
    }
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  .fadeIn {
    animation-name: fadeIn;
    animation-duration: ${props => props.theme.fx.speed};
  }

  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }

  .container {
    margin: 0 auto;
    padding: 0 10px;
    max-width: ${props => props.theme.layout.width.container};
  }
`;

export default GlobalStyles;