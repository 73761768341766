import React, { Component } from 'react';
import Slider from 'react-slick';
import { Col, Row } from 'react-styled-flexboxgrid';
import axios from '../utils/axios';
import SliderArrow from '../components/SliderArrow';
import { translate, getLanguage } from 'react-switch-lang';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import sliderPlaceholder from '../assets/slider-placeholder.png';

// STYLES
const NewsContainer = styled.div`
  background-color: transparent;
  display: block;
  border-top: 2px solid ${props => props.theme.color.text.high};
  border-bottom: 2px solid ${props => props.theme.color.text.high};
  position: relative;
  padding: ${props => props.theme.layout.gap.medium} 0 0 0;
  margin-bottom: ${props => props.theme.layout.gap.small};
  text-align: center;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    text-align: left;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
  color: ${props => props.theme.color.button.surface};

  &:hover {
    text-decoration: none;
  }
`;

const SliderContainer = styled.div`
  position: relative;
`;

const SliderBackground = styled.div`
  background-image: url(${props => props.bg});
  background-size: cover;
  margin-bottom: 2rem;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {

  }
  
  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    margin-bottom: 0;
  }
`;

// COMPONENT
class FeatureNews extends Component {
  
  state = { 
    news: null,
    currentLink: null
  }

  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
  }

  componentDidMount() {
    const lang = getLanguage();

    this.setState({
      news: this.props.news.filter(item => item.featured).sort((a, b) => b._o - a._o),
      currentLink: this.props.news[0][`title_slug_${lang}`] ? this.props.news[0][`title_slug_${lang}`] : this.props.news[0][`title_slug`]
    });

    // axios.get(`collections/get/news`)
    //   .then(response => {
        
    //     this.setState({
    //       news: response.data.entries.filter(item => item.featured).sort((a, b) => b._o - a._o),
    //       currentLink: response.data.entries[0][`title_slug_${lang}`] ? response.data.entries[0][`title_slug_${lang}`] : response.data.entries[0][`title_slug`]
    //     });
    //   })
    //   .catch(error => this.setState({ error }));
  }

  handlePrev = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.sliderRef.current.slickPrev();

  }
  
  handleNext = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.sliderRef.current.slickNext();
  }

  trimText = text => {
    return {__html: `${text.replace(/<[^>]*>?/gm, '').slice(0,209).trim()}&nbsp;[...]`};
  }

  render() {
    const { t } = this.props;
    const lang = getLanguage();

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    };

    let slider = null;

    if (this.state.news) {

      slider = (
        <SliderContainer>
          <Slider {...settings} ref={this.sliderRef}>
            {this.state.news.map((item, index) => (
              <div key={index}>
                <Row>
                  <Col xs={12} sm={6} smOffset={3} md={3} mdOffset={2}>
                    <SliderBackground bg={`${process.env.REACT_APP_ROOT}/${item.image.path}`}>
                      <StyledLink to={item[`title_slug_${lang}`] ? `/news/` + item[`title_slug_${lang}`] : `/news/` + item[`title_slug`]}>
                        <img src={sliderPlaceholder} alt="" />
                      </StyledLink>
                    </SliderBackground>
                  </Col>
                  <Col xs={12} sm={8} smOffset={2} md={5} mdOffset={0}>
                    <h3>{item[`title_${lang}`] ? item[`title_${lang}`] : item[`title`] }</h3>
                    <p dangerouslySetInnerHTML={this.trimText(item[`teaser_${lang}`] ? item[`teaser_${lang}`] : item[`teaser`])}></p>
                    <StyledLink to={item[`title_slug_${lang}`] ? `/news/` + item[`title_slug_${lang}`] : `/news/` + item[`title_slug`]}>{ t('find_out_more') }</StyledLink>
                  </Col>
                </Row>
              </div>
            ))}
          </Slider>
          <SliderArrow direction="left" prev={this.handlePrev} next={this.handleNext} />
          <SliderArrow direction="right" prev={this.handlePrev} next={this.handleNext} />
        </SliderContainer>
      );
    }

    return (
      <NewsContainer>
        {slider}
      </NewsContainer>
    );
  }
}

export default translate(FeatureNews);