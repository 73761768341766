import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import sitemap from './Sitemap';

// STYLES
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 -1rem;

  & > * {
    flex: 1;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-transform: uppercase;
  font-weight: normal;
  text-decoration: none;
  color: white;
  display: block;
  margin: 0 1rem;
  padding-bottom: 20px;

  &:hover,
  &:active,
  &.active {
    font-weight: bold;
    color: white;
    text-decoration: none;
    padding-bottom: 19px;
    
    span {
      border-bottom: 2px solid white;
    }
  }
`;

const NavLabel = styled.span`
  display: inherit;
  padding-bottom: 5px;
  border-bottom: 1px solid white;
`;

const Placeholder = styled.div`
  margin: 0 1rem;
`;

// COMPONENT
const Navigation = props => {
  const { t } = props;

  return (
    <Nav { ...props }>
      {sitemap.map(page => (
        <StyledNavLink 
          to={ page.link } 
          key={ page.alias }
          onMouseEnter={ () => props.enter(page.link) } 
          onMouseLeave={ props.leave }
        >
          
          <NavLabel>{ t(page.label) }</NavLabel>

        </StyledNavLink>
      ))}
      <Placeholder>&nbsp;</Placeholder>
    </Nav>
  );
};

export default translate(Navigation);