import React from 'react';
import Page from '../components/Page';
import { translate, getLanguage } from 'react-switch-lang';
import styled from 'styled-components';
import Slider from 'react-slick';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Teaser from '../components/Teaser';
import SliderArrow from '../components/SliderArrow';

// STYLES
const SliderItem = styled.div`
  padding: 0 1.5rem;
`;

const SliderContainer = styled.div`
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  margin-bottom: ${props => props.theme.layout.gap.large};
  position: relative;
`;

const StyledSliderArrowLeft = styled(SliderArrow)`
  left: 20px;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    left: -15px;
  }
`;

const StyledSliderArrowRight = styled(SliderArrow)`
  right: 20px;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    right: -15px;
  }
`;

// COMPONENT
class InfoPhilosophy extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      images: [],
      current: '',
      currentIndex: null
    };
    this.sliderRef = React.createRef();
  }

  componentDidMount() {
    const images = this.props.data.gallery.map(img => img.path);

    this.setState({
      images: images,
      current: ''
    });
  }

  handleClickImage = (e, image) => {
    e && e.preventDefault();
    
    this.setState({
      current: image,
      currentIndex: this.props.data.gallery.findIndex(img => img.path === image)  
    })
  }

  handleCloseModal = (e) => {
    e && e.preventDefault();
 
    this.setState({
      current: ''
    })
  }
  
  handlePrev = () => {
    this.sliderRef.current.slickPrev();
  }
  
  handleNext = () => {
    this.sliderRef.current.slickNext();
  }

  render() {
    const { t } = this.props;
    const lang = getLanguage();
    const { images, current, currentIndex } = this.state;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerPadding: '50px',
      arrows: false,
      responsive: [
        { breakpoint: 600, settings: { slidesToShow: 1 } },
        { breakpoint: 800, settings: { slidesToShow: 3 } }
      ]
    };

    return (
      <Page title={ t('nav.philosophy')}>
        <h1>{ t('nav.philosophy')}</h1>
        <Teaser className="teaser" dangerouslySetInnerHTML={{ __html: this.props.data[`intro_${lang}`] ? this.props.data[`intro_${lang}`] : this.props.data[`intro`] }}></Teaser>

        <SliderContainer>
          <Slider { ... settings } ref={ this.sliderRef }>
            {images.map(image => (
              <SliderItem>
                <img 
                  className="fluid" 
                  src={`${ process.env.REACT_APP_ROOT }/${ image }`} 
                  alt="" 
                  onClick={ e => this.handleClickImage(e, image)} 
                />
              </SliderItem>  
            ))}
          </Slider>
          <StyledSliderArrowLeft direction="left" prev={this.handlePrev} next={this.handleNext} />
          <StyledSliderArrowRight direction="right" prev={this.handlePrev} next={this.handleNext} />
        </SliderContainer>

        <h2>{ this.props.data[`headline_${lang}`] ? this.props.data[`headline_${lang}`] : this.props.data[`headline`] }</h2>
        <div dangerouslySetInnerHTML={{ __html: this.props.data[`text_${lang}`] ? this.props.data[`text_${lang}`] : this.props.data[`text`] }}></div>

        { current &&
          <Lightbox
            mainSrc={`${ process.env.REACT_APP_ROOT }/${current}`}
            nextSrc={(currentIndex + 1 >= images.length) ? `${ process.env.REACT_APP_ROOT }/${images[0]}` : `${ process.env.REACT_APP_ROOT }/${images[currentIndex + 1]}`}
            prevSrc={(currentIndex - 1 < 0) ? `${ process.env.REACT_APP_ROOT }/${images[images.length - 1]}` : `${ process.env.REACT_APP_ROOT }/${images[currentIndex - 1]}`}
            onMovePrevRequest={() =>
              this.setState({
                currentIndex: (currentIndex - 1) <= 0 ? images.length - 1 : currentIndex - 1,
                current: (currentIndex - 1) <= 0 ? images[images.length - 1] : images[currentIndex - 1]
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                currentIndex: (currentIndex + 1) >= images.length ? 0 : currentIndex + 1,
                current: (currentIndex + 1) >= images.length ? images[0] : images[currentIndex + 1]
              })
            } 
            onCloseRequest={this.handleCloseModal}
          />
        }
      </Page>
    );
  }
}

export default translate(InfoPhilosophy);