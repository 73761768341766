const Theme = {
  color: {
    primary: '#B0BC02',
    primary100: '#F0F7C1',
    secondary: '#4B2442',
    surface: {
      body: '#f5f5f5',
      light: '#FFFFFF',
      medium: '#A492A0',
      dark: '#F5F5F5'
    },
    text: {
      body: '#4a2441',
      high: '#212121',
      medium: '#666666',
      disabled: '#9E9E9E'
    },
    button: {
      surface: '#C3CF3E',
      surfaceHover: '#DCE673',
      inline: '#A0A500',
      inlineHover: '#C0D212',
      inlineDark: '#4A2441',
      inlineDarkHover: '#AA3370'
    }
  },
  font: {
    family: {
      primary: '"Roboto", sans-serif',
      secondary: '"Roboto Condensed", sans-serif'
    },
    size: {
      default: '1.6rem',
      small: '1.4rem',
      h1: '5.0rem',
      h2: '3.4rem',
      h3: '2.4rem'
    }
  },
  layout: {
    width: {
      container: '1130px',
      content: '980px'
    },
    padding: {
      tablet: '30px',
      phone: '15px'
    },
    gap: {
      default: '0.8rem',
      small: '1.5rem',
      medium: '3.8rem',
      large: '6.5rem',
      h1: '0.8rem',
      h2: '2.1rem'
    }
  },
  fx: {
    speed: '0.3s'
  },
  flexboxgrid: {
    gridSize: 12,
    gutterWidth: 3,
    outerMargin: 0,
    mediaQuery: 'only screen',
    breakpoints: {
      xs: 0,
      sm: 40, 
      md: 64,
      lg: 75
    }
  },
  bp: {
    navigation: '768px'
  }
};

export default Theme;