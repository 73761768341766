import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { translate } from 'react-switch-lang';

const PrevNextLink = styled.button`
  text-transform: uppercase;
  font-size: ${props => props.theme.font.size.small};
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: ${props => props.theme.color.primary};

  &:focus {
    outline: none;
  }
`;

const PrevContainer = styled.div`
  text-align: left;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    text-align: left;
  }
`;

const NextContainer = styled.div`
  text-align: right;
  margin-bottom: 20px;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    text-align: right;
    margin-bottom: 0;
  }
`;

const PrevLink = styled(PrevNextLink)`
  text-align: left;
`;

const PrevNextHeadline = styled.h2`
  text-align: center;
  margin-bottom: 0;
  line-height: 1.1;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    text-align: left;
    margin-bottom: 2.1rem;
  }
`;

const NextLink = styled(PrevNextLink)`
  text-align: right;
`;

const PrevCol = styled(Col)`
  order: 2;
  
  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    order: 1;
  }
`;

const TitleCol = styled(Col)`
  order: 1;
  
  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    order: 2;
  }
`;

const NextCol = styled(Col)`
  order: 3;
  
  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    order: 3;
  }
`;

const PrevNext = props => {
  const { t } = props;

  return (
    <Row>
      <PrevCol xs={6} sm={3}>
        {/* <PrevLink onClick={props.prev}>&lt; {t('prev')}</PrevLink> */}
        <PrevContainer>
          <PrevLink onClick={props.prev}>&lt; {`${props.prevTitle.slice(0, 21)}`}{props.prevTitle.length > 21 ? '...' : ''}</PrevLink>
        </PrevContainer>
      </PrevCol>
      <TitleCol xs={12} sm={6}><PrevNextHeadline>{props.actualTitle}</PrevNextHeadline></TitleCol>
      <NextCol xs={6} sm={3} className="align-right">
        {/* <NextLink onClick={props.next}>{t('next')} &gt;</NextLink> */}
        <NextContainer>
          <NextLink onClick={props.next}>{`${props.nextTitle.slice(0, 20)}`}{props.nextTitle.length > 21 ? '...' : ''} &gt;</NextLink>
        </NextContainer>
      </NextCol>
    </Row>
  );
};

export default translate(PrevNext);