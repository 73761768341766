import axios from './axios';

const getSchedule = () => axios.get(`collections/get/schedule?populate=1`);
const getNews = () => axios.get(`collections/get/news?populate=1`);
const getSingleton = singleton => axios.get(`singletons/get/${singleton}`);
const getCollection = collection => axios.get(`collections/get/${collection}`);
const getServerTime = () => axios.get('https://admin.yogabee.de/time.php');
// const getServerTime = () => axios.get('http://admin.yogabee.test/time.php');
// const getServerTime = () => axios.get('http://yogabee-admin.zirkusdigitalo.de/time.php');

export default {
  getSchedule: getSchedule,
  getNews: getNews,
  getSingleton: getSingleton,
  getCollection: getCollection,
  getServerTime: getServerTime
}