import React from 'react';
import Page from '../components/Page';
import { translate, getLanguage } from 'react-switch-lang';
import styled from 'styled-components';
import Teaser from '../components/Teaser';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-styled-flexboxgrid';
import Teacher from '../components/Teacher';
import Button from '../components/Button';

// STYLES
const TeacherContainer = styled.div`
  margin-bottom: 20px;
`;

const TeacherLink = styled(Link)`
  text-decoration: none;

  &:hover { 
    text-decoration: none;
  }
`;

const RebeccaContainer = styled.div`
  margin-bottom: 50px;
`;

// COMPONENT
const InfoTeam = props => {
  
  const { t, data, teachers, schedules } = props;
  const lang = getLanguage();

  const teachersList = teachers.filter(t => t.name_slug !== 'rebecca').sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    } else {
      return 0;
    }

  }).map(teacher => {
    let teacherCourses = [];
    
    for (let item of schedules) {
      if (item.teacher && item.teacher.name === teacher.name) {
        if (!teacherCourses.includes(item.course.title)) {
          teacherCourses.push(item.course.title);
        }
      }
    }

    return (
      <Col xs={6} sm={4} md={3}>
        <TeacherContainer>
          <TeacherLink to={`/about/team/${teacher.name_slug}`}>
            <Teacher name={teacher.name} image={teacher.image.path} courses={teacherCourses} />
          </TeacherLink>
        </TeacherContainer>
      </Col>
    );
  });

  return (
    <Page title={ t('nav.team')}>
      <h1>{ t('nav.team')}</h1>
      <Teaser className="teaser" dangerouslySetInnerHTML={{ __html: props.data[`intro_${lang}`] ? props.data[`intro_${lang}`] : props.data[`intro`] }}></Teaser>

      <RebeccaContainer>
        <Row>
          <Col xs={12} sm={4} md={3}>
            <TeacherLink to={`/about/team/rebecca`}>
              <Teacher name="Rebecca" image={ data.rebecca_image.path } courses={[]} noOverlay />
            </TeacherLink>
          </Col>
          <Col xs={12} sm={8} md={9}>
              <h2>{ data[`rebecca_headline_${lang}`] ? data[`rebecca_headline_${lang}`] : data[`rebecca_headline`] }</h2>
              <div dangerouslySetInnerHTML={{ __html: data[`rebecca_text_${lang}`] ? data[`rebecca_text_${lang}`] : data[`rebecca_text`] }}></div>
              <Button href="/about/team/rebecca">{t('more_info_rebecca')}</Button>
          </Col>
        </Row>
      </RebeccaContainer>

      <Row>{ teachersList }</Row>
    </Page>
  );
}
  
export default translate(InfoTeam);