import React from 'react';
import Button from './Button';
import styled from 'styled-components';
import { translate } from 'react-switch-lang';
import yacepLogo from '../assets/YACEP.png'

// STYLES
const FacebookLink = styled.a`
  font-size: 40px;
  margin-left: 30px;
  display: inline-block;
  line-height: 1;

  i {
    vertical-align: middle;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const YaceLogo = styled.img`
  width: 50px;
  height: 50px;
  margin-left: 30px;
`;

// COMPONENT
const SocialActions = props => {
  
  const { t } = props;
  
  return (
    <Container>
      <Button onClick={props.click} href="#">{ t('get_newsletter') }</Button>
      <FacebookLink href="https://www.facebook.com/YogaBeeDeutschland/" target="_blank" rel="noopener noreferrer"><i className="zmdi zmdi-facebook-box"></i></FacebookLink>
      <FacebookLink href="https://www.instagram.com/yogabeemunich/" target="_blank" rel="noopener noreferrer"><i className="zmdi zmdi-instagram"></i></FacebookLink>
      <FacebookLink href="https://www.youtube.com/channel/UCQBgsuguYgqa_kV6KkGtIMQ" target="_blank" rel="noopener noreferrer"><i class="zmdi zmdi-youtube"></i></FacebookLink>
      <YaceLogo src={ yacepLogo } alt="" />
    </Container>
  );
};

export default translate(SocialActions);