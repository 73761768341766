import React from 'react';
import styled from 'styled-components';

// STYLES
const Frame = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;

  /* &:hover {
    img {
      filter: blur(3px);
      -webkit-filter: blur(3px);
    }

    div {
      opacity: 1;
    }
  } */
`;

const Image = styled.img`
  filter: blur(0);
  -webkit-filter: blur(0);
  transition: all ${props => props.theme.transitionSpeed} ease-in;
  vertical-align: top;
`;

const Overlay = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(239, 241, 204, 0.8);
  transition: all ${props => props.theme.transitionSpeed} ease-in;
`;

const Name = styled.span`
  display: block;
  padding-top: 2px;
  margin-top: 2px;
  border-top: 1px solid ${props => props.theme.greyDark};
  text-transform: uppercase;
`;

const CourseList = styled.div`
  list-style: none;
  padding: 5px 10px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
`;

const CourseListItem = styled.div`
  line-height: 1.2;
`;

// COMPONENT
const Teacher = props => {
  
  return (
    <div>
      <Frame>
        <Image className="fluid" src={`${ process.env.REACT_APP_ROOT }/${ props.image }`} alt={ props.name } />
        {/* {props.noOverlay ? null : (
          <Overlay>
            <CourseList>
              {props.courses.map(course => (
                <CourseListItem><small>{course}</small></CourseListItem>
              ))}
            </CourseList>
          </Overlay>
        )} */}
      </Frame>
      <Name>{props.name}</Name>
    </div>
  )
};

export default Teacher;