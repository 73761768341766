import React from 'react';
import styled from 'styled-components';
import { translate, getLanguage } from 'react-switch-lang';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import NewsItem from '../components/NewsItem';
import Page from '../components/Page';

const MasonryContainer = styled.div`
  margin: 0 -1.5rem;
`;

const News = props => {

  const { t } = props;
  const lang = getLanguage();

  let filteredNews = props.news.sort((a, b) => b._o - a._o);
  
  let title = t('nav.news');

  switch (props.filter) {
    case 'workshop':
      title = t('workshops');
      break;
    case 'event':
      title = t('events');
      break;
    case 'misc':
      title = t('misc');
      break;
    default:
      break;
  }

  if (props.filter !== 'all') {
    filteredNews = props.news.filter(item => item.type === props.filter);
  }

  return (
    <Page title={t('nav.news')}>
      <h1>{ title }</h1>
      <MasonryContainer>
        <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 550: 2, 900: 3}}>
          <Masonry gutter={15}>
            {filteredNews.map((item, index) => {
              let teacher = '';
              if (item.teacher) {
                teacher = item.teacher.name;
              } else if (item.teacher_custom) {
                teacher = item.teacher_custom;
              }

              return (
                <NewsItem 
                  key = { index }
                  image = { item.image.path } 
                  title = { item[`title_${lang}`] ? item[`title_${lang}`] : item[`title`] }
                  type = { t(item.type) } 
                  headline = { item[`headline_${lang}`] ? item[`headline_${lang}`] : item[`headline`] }
                  teacher = { teacher }
                  date = { item[`date_${lang}`] ? item[`date_${lang}`] : item[`date`] }
                  time = { item[`time_${lang}`] ? item[`time_${lang}`] : item[`time`] }
                  teaser = { item[`teaser_${lang}`] ? item[`teaser_${lang}`] : item[`teaser`] }
                  tags = { item[`tags_${lang}`] ? item[`tags_${lang}`] : item[`tags`] }
                  link = { item[`title_slug_${lang}`] ? item[`title_slug_${lang}`] : item[`title_slug`] }
                  mbo_link = {item.mbo_link || null} />
              );
            })}
          </Masonry>
        </ResponsiveMasonry>
      </MasonryContainer>
    </Page>
  );
}

export default translate(News);