import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const MobileToggle = (props) => {
  let icon = null;

  if (!props.icon) {
    icon = (
      <svg width="39px" height="21px" viewBox="0 0 39 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
          <g id="320-CORE-/-HEADER" transform="translate(-141.000000, -19.000000)" stroke="#FFFFFF" stroke-width="3">
            <g id="Group-2" transform="translate(143.000000, 20.000000)">
              <path d="M0.426829268,0.5 L34.8461914,0.5" id="Line-3"></path>
              <path d="M0.426829268,9.5 L34.8461914,9.5" id="Line-3-Copy"></path>
              <path d="M0.426829268,18.5 L34.8461914,18.5" id="Line-3-Copy-2"></path>
            </g>
          </g>
        </g>
      </svg>
    );
  } else {
    icon = (
      <svg width="25px" height="26px" viewBox="0 0 25 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
          <g id="320-CORE-/-HEADER-Copy" transform="translate(-148.000000, -18.000000)" stroke="#FFFFFF" stroke-width="3">
            <g id="Group" transform="translate(149.000000, 19.000000)">
              <path d="M0.5,1 L22,22.5" id="Line-2"></path>
              <path d="M1,1 L22.5,22.5" id="Line-2-Copy" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }

  return (
    <Button onClick={props.toggle} id="toggle" {...props}>
      {icon}
    </Button>
  );
}
  
export default MobileToggle;