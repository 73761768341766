import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-styled-flexboxgrid';
import Schedule from '../components/Schedule';
import NotificationCheckIn from '../components/NotificationCheckIn';
import PrevNext from '../components/PrevNext';
import FigCaption from '../components/FigCaption';
import Page from '../components/Page';
import { translate, getLanguage } from 'react-switch-lang';
import Button from '../components/Button';

const TeacherLink = styled(Link)`
  margin-bottom: 1.8rem;
  text-decoration: none;
  display: block;

  &:hover {
    text-decoration: none;
  }
`;

const ScheduleLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizeSmall};

  &:hover {
    text-decoration: none;
  }
`;

const PdfLink = styled.a`
  display: block;
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  text-align: center;
  display: block;
  margin: 5rem 0 3rem 0;
`;

class ServiceCourse extends React.Component {
  
  state = { 
    courses: null,
    currentCourse: null,
    currentIndex: null
  };

  componentWillMount() {
    const courses = this.props.courses;
    const index = courses.findIndex(elem => elem.title_slug === this.props.match.params.slug);

    this.setState({
      courses: courses,
      currentIndex: index,
      currentCourse: courses[index]
    });
  }

  prevHandler = () => {
    const index = (this.state.currentIndex > 0) ? this.state.currentIndex - 1 : this.state.courses.length - 1;

    this.setState(prevState => {
      return {
        currentIndex: index,
        currentCourse: prevState.courses[index]
      }
    });
  };

  nextHandler = () => {
    const index = (this.state.currentIndex === this.state.courses.length - 1) ? 0 : this.state.currentIndex + 1

    this.setState(prevState => {
      return {
        currentIndex: index,
        currentCourse: prevState.courses[index]
      }
    })
  };

  getTeachers = () => {
    const teachers = [];
    const teachersNames = [];

    for (let schedule of this.props.schedules) {
      if (schedule.course.title === this.state.currentCourse.title && !teachersNames.includes(schedule.teacher.name)) {
        teachers.push(schedule.teacher);
        teachersNames.push(schedule.teacher.name);
      }
    }
    
    return teachers;
  };

  render() {
    const teachers = this.getTeachers().map(teacher => (
      <TeacherLink to={`/info/team/${teacher.name_slug}`}>
        <FigCaption imgSrc={`${process.env.REACT_APP_ROOT}/${teacher.image.path}`} imgAlt={teacher.name} caption={teacher.name} />
      </TeacherLink>
    ));

    const { t } = this.props;
    const lang = getLanguage();

    let prevCourse = null;

    if (this.state.currentIndex === 0 ) {
      prevCourse = this.state.courses[this.state.courses.length - 1][`title_${lang}`] ? this.state.courses[this.state.courses.length - 1][`title_${lang}`] : this.state.courses[this.state.courses.length - 1][`title`]; 
    } else {
      prevCourse = this.state.courses[this.state.currentIndex - 1][`title_${lang}`] ? this.state.courses[this.state.currentIndex - 1][`title_${lang}`] : this.state.courses[this.state.currentIndex - 1][`title`];
    }
    
    let nextCourse = null;

    if (this.state.currentIndex === this.state.courses.length - 1) {
      nextCourse = this.state.courses[0][`title_${lang}`] ? this.state.courses[0][`title_${lang}`] : this.state.courses[0][`title`];
    } else {
      nextCourse = this.state.courses[this.state.currentIndex + 1][`title_${lang}`] ? this.state.courses[this.state.currentIndex + 1][`title_${lang}`] : this.state.courses[this.state.currentIndex + 1][`title`];
    }

    let coursePdf = null;

    if (this.state.currentCourse.pdf) {
      coursePdf = this.state.currentCourse[`pdf_text_${lang}`] ? this.state.currentCourse[`pdf_text_${lang}`] : this.state.currentCourse[`pdf_text`];
    }
      
    return (
      <Page title={this.state.currentCourse[`title_${lang}`] ? this.state.currentCourse[`title_${lang}`] : this.state.currentCourse[`title`]}>
        {/* <h1>{this.props.data[`intro_headline_${lang}`] ? this.props.data[`intro_headline_${lang}`] : this.props.data[`intro_headline`]}</h1>
        <div className="teaser" dangerouslySetInnerHTML={{ __html: this.props.data[`intro_text_${lang}`] ? this.props.data[`intro_text_${lang}`] : this.props.data[`intro_text`] }}></div> */}

        <PrevNext actualTitle={this.state.currentCourse[`title_${lang}`] ? this.state.currentCourse[`title_${lang}`] : this.state.currentCourse[`title`] } prev={this.prevHandler} next={this.nextHandler} prevTitle={prevCourse} nextTitle={nextCourse} />

        <Row className="mb-section">
          <Col xs={12} md={3}>
            {/* {teachers} */}
          </Col>
          <Col xs={12} md={6}>
            <div dangerouslySetInnerHTML={{ __html: this.state.currentCourse[`description_${lang}`] ? this.state.currentCourse[`description_${lang}`] : this.state.currentCourse[`description`] }}></div>
            <PdfLink href={`${process.env.REACT_APP_ROOT}/${this.state.currentCourse.pdf}`} target="_blank" rel="noopener noreferrer">{coursePdf}</PdfLink>
          </Col>
        </Row>

        {/* <Row>
          <Col xs={12} md={9}><h2>{this.state.currentCourse[`title_${lang}`] ? this.state.currentCourse[`title_${lang}`] : this.state.currentCourse[`title`]}</h2></Col>
        </Row> */}
        
        {/* <Schedule className="mb-section" filter="course" by={this.state.currentCourse.title} data={this.props.schedules} layoutType="course" /> */}
        {/* <StyledButton large href="/offer/schedule">{t('complete_schedule')}</StyledButton> */}

        <NotificationCheckIn 
          headline={this.props.data[`notification_headline_${lang}`] ? this.props.data[`notification_headline_${lang}`] : this.props.data[`notification_headline`]}
          text={this.props.data[`notification_text_${lang}`] ? this.props.data[`notification_text_${lang}`] : this.props.data[`notification_text`]} />
      </Page>
    );
  }
}

export default translate(ServiceCourse);