import React from 'react';
import Page from '../components/Page';
import { translate, getLanguage } from 'react-switch-lang';
import styled from 'styled-components';
import Teaser from '../components/Teaser';

// STYLES
const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    flex-direction: row;
  }
`;

const Box = styled.div`
  flex: 1;
  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    height: 100%;
  }
`;

const LeftBox = styled(Box)`
  padding: 0 0 3rem 0;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    padding: 0 3rem 0 0;
  }
`;

const RightBox = styled(Box)`
  padding: 3rem 0 0 0;
  border-top: 1px solid ${props => props.theme.greyDark};

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    border-left: 1px solid ${props => props.theme.greyDark};
    border-top: none;
    padding: 0 0 0 3rem;
  }
`;

// COMPONENT
const OfferCustom = props => {
  
  const { t, data } = props;
  const lang = getLanguage();

  return (
    <Page title={ t('nav.customYoga')}>
      <h1>{ t('nav.customYoga')}</h1>
      <Teaser className="teaser" dangerouslySetInnerHTML={{ __html: data[`text_${lang}`] ? data[`text_${lang}`] : data[`text`] }}></Teaser>
      <BoxContainer>
        <LeftBox>
          <h2>{ data[`business_headline_${lang}`] ? data[`business_headline_${lang}`] : data[`business_headline`] }</h2>
          <div dangerouslySetInnerHTML={{ __html: data[`business_text_${lang}`] ? data[`business_text_${lang}`] : data[`business_text`] }}></div>
        </LeftBox>
        <RightBox>
          <h2>{ data[`individual_headline_${lang}`] ? data[`individual_headline_${lang}`] : data[`individual_headline`] }</h2>
          <div dangerouslySetInnerHTML={{ __html: data[`individual_text_${lang}`] ? data[`individual_text_${lang}`] : data[`individual_text`] }}></div>
        </RightBox>
      </BoxContainer>
    </Page>
  );
}
  
export default translate(OfferCustom);