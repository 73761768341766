import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import ToggleIcon from './ToggleIcon';

// STYLES
const Acc = styled.div`
  margin-bottom: 1.8rem;
  margin-bottom: 0;

  ${props => props.faq && css`
    h2 {
      text-transform: none;
      font-size: 2.0rem;
    }
  `}
`;

const AccTitle = styled.h2`
  background-color: ${props => props.theme.color.body};
  color: ${props => props.theme.color.primary};
  border-bottom: 2px solid ${props => props.theme.color.primary};
  padding: 10px 0;
  margin: 0;
  line-height: 1;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AccToggleIcon = styled(ToggleIcon)`
  width: 26px;
`;

const AccContent = styled.div`
  display: none;
  padding: 12px 0 16px 0;

  .open & {
    display: block;
  }
`;

// COMPONENT
const Accordeon = props => {
  
  let accordeonState = useState(props.open);
  const container = React.createRef();

  return (
    <Acc ref={container} {...props} className={props.open ? 'open' : ''}>
      <AccTitle onClick={ props.click }>{props.title}<AccToggleIcon isOpen={props.open} /></AccTitle>
      <AccContent>{props.children}</AccContent>
    </Acc>
  );
};

export default Accordeon;