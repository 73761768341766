import React from 'react';
import styled from 'styled-components';
import Page from '../components/Page';
import Button from '../components/Button';
import { translate, getLanguage } from 'react-switch-lang';
import Schedule from '../components/Schedule';
import Teaser from '../components/Teaser';
import FeatureNews from '../components/FeatureNews';
import Video from '../components/Video';

// STYLES
const Hero = styled.img`
  vertical-align: top;
`;

const HeroContainer = styled.div`
  position: relative;
  border-bottom: 2px solid ${props => props.theme.color.text.high};
  padding-bottom: ${props => props.theme.layout.gap.medium};
  margin-bottom: ${props => props.theme.layout.gap.medium};
`;

const HeroText = styled.h1`
  position: absolute;
  top: 0;
  left: 0;
  text-transform: uppercase;
  padding: 30px 25px;
  line-height: 1.2;
`;

const StyledTeaser = styled(Teaser)`
  margin-bottom: ${props => props.theme.layout.gap.small};
`;

const StyledButton = styled(Button)`
  margin: 0 auto;
  display: block;
  text-align: center;
`;

const ScheduleContainer = styled.div`
  margin-bottom: ${props => props.theme.layout.gap.medium};
`;

const StyledSchedule = styled(Schedule)`
  margin-bottom: ${props => props.theme.layout.gap.medium};
`;

const ScheduleHeader = styled.h2`
  background-color: ${props => props.theme.color.body};
  color: ${props => props.theme.color.button.surface};
  border-bottom: 2px solid ${props => props.theme.color.button.surface};
  padding-bottom: 10px;
  line-height: 1;
  margin: 0;
`;

const NewsContainer = styled.div`
  margin-bottom: ${props => props.theme.layout.gap.medium};
`;

const SectionHeadline = styled.h2`
  line-height: 1;
  margin-bottom: ${props => props.theme.layout.gap.small};
`;

const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const HeroImageContainer = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

const EverspotsWidget = styled.iframe`
  width: 100%;
  height: 380px;
  border: none;
  
  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    height: 500px;
  }
`;

// COMPONENT
const Home = props => {
  
  const weekDays = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
  const day = new Date().getDay();
  const { t, data } = props;
  const date = new Date();

  const month ={
    'de': ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    'en': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  };
  const lang = getLanguage();

  const filterDay = day > 0 ? weekDays[day - 1] : weekDays[6];

  return (
    <Page title="Home">
      <HeroContainer>
        <HeroImageContainer>
          <Hero className="fluid" src={`${ process.env.REACT_APP_ROOT }/${data.image.path}`} alt="" />
          <HeroOverlay />
        </HeroImageContainer>
        <HeroText dangerouslySetInnerHTML={{ __html: data[`image_text_${lang}`] ? data[`image_text_${lang}`] : data[`image_text`] }} style={{color: data.image_text_color}}></HeroText>
        <StyledTeaser dangerouslySetInnerHTML={{ __html: data[`image_caption_${lang}`] ? data[`image_caption_${lang}`] : data[`image_caption`] }}></StyledTeaser>
        <StyledButton large href="/about/philosophy">{ t('more_studio') }</StyledButton>
      </HeroContainer>

      <SectionHeadline>{ t('news') }</SectionHeadline>
      <NewsContainer>
        <FeatureNews news={ props.news } />
        <StyledButton large href="/news/all">{ t('more_news') }</StyledButton>
      </NewsContainer>

      <SectionHeadline>{ t('schedule') }</SectionHeadline>
      <ScheduleContainer>
        {/* <ScheduleHeader>Heute, {date.getDate()}. {month[lang][date.getMonth()]} {date.getFullYear()}</ScheduleHeader>
        <StyledSchedule data={props.schedules} filter="weekday" by={filterDay} layoutType="schedule" />
        <StyledButton large href="/offer/schedule">{ t('complete_schedule') }</StyledButton> */}
        <EverspotsWidget src="https://www.eversports.de/widget/w/8um9im" frameborder="0"></EverspotsWidget>
      </ScheduleContainer>

      <SectionHeadline>{ t('studio_preview') }</SectionHeadline>
      <div dangerouslySetInnerHTML={{ __html: data.video_text }}></div>
      <Video youtubeId={data.video} />
    </Page>
  );
};

export default translate(Home);