import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Home from './pages/Home';
import InfoPhilosophy from './pages/InfoPhilosophy';
import InfoTeam from './pages/InfoTeam';
import InfoTeacher from './pages/InfoTeacher';
import InfoStudio from './pages/InfoStudio';
import InfoFaq from './pages/InfoFaq';
import OfferSchedule from './pages/OfferSchedule';
import OfferCourse from './pages/OfferCourse';
import OfferPrices from './pages/OfferPrices';
import OfferCustom from './pages/OfferCustom';
import News from './pages/News';
import NewsDetail from './pages/NewsDetails';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Imprint from './pages/Imprint';
import Agb from './pages/Agb';
import NotFound from './pages/NotFound';
import Ausbildung from './pages/Ausbildung';

const Routes = props => {

  const data = props.data;

  return (
    <Switch>
      <Route path="/" exact render={props => (
        <Home { ...props } data={ data.home } schedules={ data.schedules } news={ data.news } />
      )} />
      
      <Route path="/about" exact render={() => (
        <Redirect to="/about/philosophy" />
      )} />
        
      <Route path="/about/philosophy" render={props => (
        <InfoPhilosophy { ...props } data={ data.philosophy } />
      )} />

      <Route path="/about/team" exact render={props => (
        <InfoTeam { ...props } data={ data.team } teachers={ data.teachers } schedules={ data.schedules } />
      )} />
                    
      <Route path="/about/team/:slug" render={props => (
        <InfoTeacher { ...props } data={ data.team } teachers={ data.teachers } schedules={ data.schedules } />
      )} />

      <Route path="/about/studio" exact render={props => (
        <InfoStudio {...props} data={ data.studio } />
      )} />

      <Route path="/about/faq" render={props => (
        <InfoFaq {...props} data={ data.faq } />
      )} />

      <Route path="/offer" exact render={() => (
        <Redirect to="/offer/schedule" />
      )} />

      <Route path="/offer/schedule" render={props => (
        <OfferSchedule {...props} data={ data.schedule } schedules={ data.schedules } />
      )} />

      <Route path="/offer/course/:slug" render={props => (
        <OfferCourse {...props} data={ data.course } courses={ data.courses } schedules={ data.schedules } />
      )} />

      <Route path="/offer/prices" render={props => (
        <OfferPrices {...props} data={ data.prices } notification={ data.course } />
      )} />
      
      <Route path="/offer/custom-yoga" render={props => (
        <OfferCustom {...props} data={ data.customYoga } />
      )} />

      <Route path="/news" exact render={() => (
        <Redirect to="/news/all" />
      )} />
      
      <Route path="/news/all" render={props => (
        <News { ...props } filter="all" news={ data.news } />
      )} />
                  
      <Route path="/news/workshops" render={props => (
        <News { ...props } filter="workshop" news={ data.news } />
      )} />

      <Route path="/news/events" render={props => (
        <News { ...props } filter="event" news={ data.news } />
      )} />
                  
      <Route path="/news/misc" render={props => (
        <News { ...props } filter="misc" news={ data.news } />
      )} />
      
      <Route path="/news/:slug" render={props => (
        <NewsDetail { ...props } news={ data.news } />
      )} />

      <Route path="/contact" render={props => (
        <Contact { ...props } data={ data.contact } />
      )} />

      <Route path="/privacy" render={props => (
        <Privacy { ...props } data={ data.privacy } />
      )} />
                  
      <Route path="/imprint" render={props => (
        <Imprint { ...props } data={ data.imprint } />
      )} />
                  
      <Route path="/agb" render={props => (
        <Agb { ...props } data={ data.agb } />
      )} />

      <Route path="/ausbildung" render={props => (
        <Ausbildung { ...props } data={ data.ausbildung } />
      )} />

      <Route component={ NotFound } />
    </Switch>
  );
}

export default withRouter(Routes);