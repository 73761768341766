import React from 'react';
import Page from '../components/Page';
import { translate, getLanguage } from 'react-switch-lang';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import IFrame from 'react-iframe';

// STYLES
const MapContainer = styled.div`
  height: 400px;
  position: relative;
`;

const PartnerContainer = styled.div`
  margin-top: 30px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

const StyledIFrame = styled(IFrame)`
  border: none;
  width: 100%;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}) {
    width: 540px;
  }
`;

// COMPONENT
const Contact = props => {

  const { t, data } = props;
  const lang = getLanguage();

  return(
    <Page title={ t('nav.contact')}>
      <h1>{ t('nav.contact')}</h1>
      <Row>
        <Col xs={12} sm={6}>
          <MapContainer>
            <StyledIFrame 
              url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10651.12684236382!2d11.537383!3d48.1337329!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x884b93bce3d1ffbe!2sYogaBee!5e0!3m2!1sde!2sde!4v1575359621788!5m2!1sde!2sde"
              width="540" height="400" />
            {/* <Map google={props.google} initialCenter={{lat: 48.133752, lng: 11.537399}} zoom={17}></Map> */}
          </MapContainer>
        </Col>
        <Col xs={12} sm={6}>
          <div dangerouslySetInnerHTML={{ __html: data[`text_${lang}`] ? data[`text_${lang}`] : data[`text`] }}></div>
        </Col> 
        <Col xs={12} sm={6}>
          <PartnerContainer dangerouslySetInnerHTML={{ __html: data[`partners_${lang}`] ? data[`partners_${lang}`] : data[`partners`] }}></PartnerContainer>
        </Col> 
      </Row>
    </Page>
  );
};

export default GoogleApiWrapper({
  apiKey: ('AIzaSyDldDGZd9A5SJcWB3YEy2_q2WoYsWS-SAk')
})(translate(Contact))