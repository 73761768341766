import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;

const Image = styled.img`
  padding-bottom: 0.8rem;
  margin-bottom: 1.1rem;
  border-bottom: 1px solid ${props => props.theme.greyDark};
`;

const Caption = styled.figcaption`
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizeSmall};
`;

const FigCaption = props => (
  <Container>
    <Image className="fluid" src={props.imgSrc} alt={props.imgAlt} />
    <Caption>{props.caption}</Caption>
  </Container>
);

export default FigCaption;