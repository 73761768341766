import React from 'react';
import { translate } from 'react-switch-lang';
import { Link, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import CookieConsent from 'react-cookie-consent';
import cockpit from './utils/cockpit';
import Routes from './Routes';
import Layout from './components/Layout';
import Header from './components/Header';

class App extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    cockpit.getSchedule().then(res => {
      this.setState({ schedules: res.data.entries });
    });
    // cockpit.getNews().then(res => {
    //   this.setState({ news: res.data.entries });
    // });
    cockpit.getCollection('teachers').then(res => {
      this.setState({ teachers: res.data.entries });
    });
    cockpit.getCollection('courses').then(res => {
      this.setState({ courses: res.data.entries });
    });
    cockpit.getSingleton('home').then(res => {
      this.setState({ home: res.data });
    });
    cockpit.getSingleton('philosophy').then(res => {
      this.setState({ philosophy: res.data });
    });
    cockpit.getSingleton('team').then(res => {
      this.setState({ team: res.data });
    });
    cockpit.getSingleton('studio').then(res => {
      this.setState({ studio: res.data });
    });
    cockpit.getSingleton('faq').then(res => {
      this.setState({ faq: res.data });
    });
    cockpit.getSingleton('schedule').then(res => {
      this.setState({ schedule: res.data });
    });
    cockpit.getSingleton('prices').then(res => {
      this.setState({ prices: res.data });
    });
    cockpit.getSingleton('custom_yoga').then(res => {
      this.setState({ customYoga: res.data });
    });
    cockpit.getSingleton('contact').then(res => {
      this.setState({ contact: res.data });
    });
    cockpit.getSingleton('agb').then(res => {
      this.setState({ agb: res.data });
    });
    cockpit.getSingleton('imprint').then(res => {
      this.setState({ imprint: res.data });
    });
    cockpit.getSingleton('privacy').then(res => {
      this.setState({ privacy: res.data });
    });
    cockpit.getSingleton('course').then(res => {
      this.setState({ course: res.data });
    });
    cockpit.getSingleton('ausbildung').then(res => {
      this.setState({ ausbildung: res.data });
    });

    cockpit.getServerTime().then(res => {
      this.setState({ serverTime: res.data });

      cockpit.getNews().then(res => {
        let parsedServerTime = new Date(this.state.serverTime).getTime();

        let newsData = res.data.entries.filter(entry => {
          if (!entry.release_date) {
            return true;
          }

          let parsedReleased = new Date(entry.release_date).getTime();;

          if (parsedServerTime >= parsedReleased) {
            return true;
          }

          return false;
        });
        
        // this.setState({ news: res.data.entries });
        this.setState({ news: newsData });
      });
    });
  }

  render() {
    let result = null;
    const { t } = this.props;

    if (
      this.state.course && 
      this.state.imprint &&
      this.state.agb &&
      this.state.privacy &&
      this.state.schedules &&
      this.state.schedule &&
      this.state.home &&
      this.state.philosophy &&
      this.state.team &&
      this.state.teachers &&
      this.state.courses &&
      this.state.studio &&
      this.state.faq &&
      this.state.prices &&
      this.state.customYoga &&
      this.state.news &&
      this.state.contact &&
      this.state.serverTime && 
      this.state.ausbildung
     ) {
      result = (
        <Layout>
          <Header />
          <main>
            <TransitionGroup>
              <CSSTransition
                key={this.props.location.key}
                classNames="fade"
                timeout={{ enter: 300, exit: 300 }}
                mountOnEnter={true}
                unmountOnExit={true}
              >
                <Routes data={this.state} />
              </CSSTransition>
            </TransitionGroup>
          </main>
          <CookieConsent buttonText="OK">
            { t('cookie') } <Link to="/privacy">{ t('more_info') }</Link>
          </CookieConsent>
        </Layout>
      );
    }

    return <div>{ result }</div>
  }
}

export default translate(withRouter(App));
