import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Modal from 'react-responsive-modal';
import { translate, getLanguage } from 'react-switch-lang';
import Button from './Button';
import moment from 'moment';

// STYLES
const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid ${props => props.theme.color.text.high};

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    flex-direction: row;
  }
`;

const DayCol = styled.div`
  align-self: flex-start;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    width: 3%;
    align-self: auto;
  }
`;

const DateCol = styled.div`
  width: 100%;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    width: 14%;
    width: 22%;
  }
`;

const TimeCol = styled.div`
  width: 100%;

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    width: 10%;
  }
`;

const TitleCol = styled.div`
  font-weight: bold;
  width: 100%;
  
  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    /* width: ${props => props.type === 'schedule' ? '48%' : '38%' }; */
    width: ${props => props.type === 'schedule' ? '38%' : '28%' };
    flex: 1;
  }
`;

const GroupCol = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  
  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    width: 38%;
    width: 40%;
    justify-content: flex-end;
  }
`;

const TeacherCol = styled.div`
  width: 25%;
`;

const TypeCol = styled.div`
  width: 25%;
`;

const ActionCol = styled.div`
  width: 43%;
  text-align: right;
`;

// const Button = styled.a`
//   text-decoration: none;
//   border: 1px solid ${props => props.theme.color.text.disabled};
//   border-radius: 5px;
//   padding: 3px 10px;
//   width: 100%;
//   max-width: 100px;
//   text-align: center;
//   display: inline-block;

//   &:hover {
//     text-decoration: inherit;
//   }
// `;

const Hint = styled.span`
  margin-left: 5px;
  cursor: pointer;
`;

// COMPONENT
class Schedule extends Component {

  state = {
    modalIsOpen: false
  }

  openModalHandler = (e, content) => {
    this.setState({ 
      modalIsOpen: true,
      modalContent: content
    });
  };

  closeModalHandler = () => {
    this.setState({ 
      modalIsOpen: false,
      modalType: null
    });
  }

  render() {
    let schedule = [];
    const lang = getLanguage();

    const modalStyles = {
      modal: {
        maxWidth: '600px',
        width: '90%',
        border: '2px solid #B0BB00',
        paddingBottom: '20px',
      },
      closeButton: {
        cursor: 'pointer',
        outline: 'none'
      },
      overlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.9)'
      }
    };

    const { t } = this.props;

    if (this.props.data.length > 0) {
      if (this.props.filter === 'course') {
        schedule = this.props.data
          .filter(item => item[this.props.filter].title === this.props.by)
          .sort((a, b) => { 
            if (a.time < b.time) {
              return -1;
            }
            if (a.time > b.time) {
              return 1;
            }

            return 0;
          });
      } else if (this.props.filter === 'teacher') { 
        schedule = this.props.data
          .filter(item => item[this.props.filter] && item[this.props.filter].name === this.props.by)
          .sort((a, b) => { 

            if (a.time < b.time) {
              return -1;
            }
            if (a.time > b.time) {
              return 1;
            }

            return 0;
          });
      } else {
        const filtered = this.props.data.filter(item => item[this.props.filter] === this.props.by);

        schedule = this.props.data
          .filter(item => item[this.props.filter] === this.props.by)
          .sort((a, b) => { 
            if (a.time < b.time) {
              return -1;
            }
            if (a.time > b.time) {
              
              return 1;
            }

            return 0;
          });
      }
    }

    let scheduleItems = [];

    if (schedule.length > 0) {
      scheduleItems = schedule.sort((a, b) => {
        if (a.dates && b.dates) {
          let aDates = a.dates.split('–');
          let bDates = b.dates.split('–');

          let aDatesSplit = aDates[0].split('.');
          let bDatesSplit = bDates[0].split('.');
          
          let aDatesDate = new Date(`20${aDatesSplit[2]}-${aDatesSplit[1]}-${aDatesSplit[0]}`).getTime();
          let bDatesDate = new Date(`20${bDatesSplit[2]}-${bDatesSplit[1]}-${bDatesSplit[0]}`).getTime();
  
          // if (a.day_number < b.day_number) {
          if (aDatesDate < bDatesDate) {
            return -1;
          }
          // if (a.day_number > b.day_number) {
            if (aDatesDate > bDatesDate) {
              return 1;
            }
            
            return 0;
          }
      }).map((item, index) => {
        let statusLink = null;

        if (item.status === 'canceled') {
          statusLink = <Button disabled schedule href="https://clients.mindbodyonline.com/classic/ws?studioid=-45092&stype=-102&sView=day&sLoc=0">{t(item.status)}</Button>;
        } else if (item.type === 'class' || item.type === 'class_kk') {
          statusLink = <Button schedule href="https://clients.mindbodyonline.com/classic/ws?studioid=-45092&stype=-7&sView=day&sLoc=0">{t(item.status)}</Button>;
        } else if (item.type === 'course' || item.type === 'course_kk') {
          if (item.status === 'registration') {
            statusLink = <Button schedule href="https://clients.mindbodyonline.com/classic/ws?studioid=-45092&stype=-102&sView=day&sLoc=0">{t(item.status)}</Button>;
          } else if (item.status === 'waitinglist') {
            statusLink = <Button schedule href="https://clients.mindbodyonline.com/classic/ws?studioid=-45092&stype=-102&sView=day&sLoc=0">{t(item.status)}</Button>;
          } else if (item.status === 'restplaces') {
            statusLink = <Button schedule href="https://clients.mindbodyonline.com/classic/ws?studioid=-45092&stype=-102&sView=day&sLoc=0">{t(item.status)}</Button>;
          } else if (item.status === 'checkin') {
            statusLink = <Button schedule href="https://clients.mindbodyonline.com/classic/ws?studioid=-45092&stype=-102&sView=day&sLoc=0">{t(item.status)}</Button>;
          } else if (item.status === 'online') {
            statusLink = <Button schedule href="https://clients.mindbodyonline.com/classic/ws?studioid=-45092&stype=-102&sView=day&sLoc=0">{t(item.status)}</Button>;
          } else if (item.status === 'openair') {
            statusLink = <Button schedule href="https://clients.mindbodyonline.com/classic/ws?studioid=-45092&stype=-102&sView=day&sLoc=0">{t(item.status)}</Button>;
          } 
        } 

        let langHint = null;

        if (item.hint) {
          langHint = item[`hint_${lang}`] ? item[`hint_${lang}`] : item.hint;
        }

        let titleCol = (
          <TitleCol type={ this.props.layoutType }>
            <Link to={`/offer/course/${item.course.title_slug}`}>
              {item.course[`title_${lang}`] ? item.course[`title_${lang}`] : item.course[`title`]}
            </Link>
            {item.hint ? <Hint><i className="zmdi zmdi-info-outline" onClick={(e) => this.openModalHandler(e, langHint)}></i></Hint> : null}
          </TitleCol>
        );

        if (this.props.layoutType === 'course') {
          titleCol = <TitleCol type={ this.props.layoutType }></TitleCol>;
        }

        return (
          <FlexRow>
            {this.props.layoutType !== 'schedule' ? <DayCol>{item.weekday ? t(item.weekday) : ''}</DayCol> : null}
            <DayCol>{t(item.weekday)}</DayCol>
            <DateCol>{ item.dates }</DateCol>

            { titleCol }

            <TimeCol>{ item.time }</TimeCol>

            <GroupCol>
              {this.props.layoutType !== 'teacher' && item.teacher ? <TeacherCol><Link to={`/about/team/${ item.teacher.name_slug}` }>{ item.teacher.name }</Link></TeacherCol> : null}
              {/* <TypeCol>{ t(item.type) }</TypeCol> */}
              {/* <ActionCol>{ statusLink }</ActionCol> */}
              <ActionCol><Button href={item.link}>{ t(item.status) }</Button></ActionCol>
            </GroupCol>
          </FlexRow>
        );
      });
    }

    

    if (schedule.length > 0) {
      return (
        <div { ...this.props }>
          <div>
            { scheduleItems }
          </div>

          <Modal 
            styles={modalStyles}
            open={this.state.modalIsOpen} 
            onClose={this.closeModalHandler} 
            center
          >
            <h2>Info</h2>
            <div dangerouslySetInnerHTML={{ __html: this.state.modalContent }}></div>
            {/* {this.state.modalContent} */}
          </Modal>
        </div>
      );
    } else { 
      return null;
    }
  }
}

export default translate(Schedule);