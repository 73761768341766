import React, { Fragment } from 'react';
import styled from 'styled-components';

const SliderArrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  z-index: 100;
  display: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}em) {
    display: block;
  }
}
`;

const SliderArrowPrev = styled(SliderArrow)`
  left: 0;
`;

const SliderArrowNext = styled(SliderArrow)`
  right: 0;
`;

const SliderArrows = (props) => {

  const arrowLeft = (
    <SliderArrowPrev onClick={props.prev} { ...props }>
      <svg width="14px" height="28px" viewBox="0 0 18 28" xmlns="http://www.w3.org/2000/svg">
        <g id="Pfeil_links" fill="#000000">
          <polygon id="Shape" points="14.6 27.9 17.6 24.8 6.9 14.1 17.6 3.4 14.6 0.3 0.7 14.1"></polygon>
        </g>
      </svg>
    </SliderArrowPrev>
  );

  const arrowRight = (
    <SliderArrowNext onClick={props.next} { ...props }>
      <svg width="14px" height="28px" viewBox="0 0 18 28" xmlns="http://www.w3.org/2000/svg">
        <g id="Pfeil_rechts" fill="#000000">
          <polygon id="Shape" points="3.8 0.3 0.7 3.4 11.5 14.1 0.7 24.8 3.8 27.9 17.6 14.1"></polygon>
        </g>
      </svg>
    </SliderArrowNext>
  );

  return (
    <Fragment>
      { props.direction === 'right' ? arrowRight : arrowLeft }
    </Fragment>
  );
}

export default SliderArrows;