import React from "react";
import styled from "styled-components";
import { translate } from "react-switch-lang";
import { Link } from "react-router-dom";
import Button from "../components/Button";

const NewsContainer = styled.div`
  border-bottom: 3px solid ${(props) => props.theme.greyDark};

  a {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
  }

  iframe {
    width: 100%;
  }
`;

const NewsImage = styled.img`
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid ${(props) => props.theme.greyDark};
`;

const NewsType = styled.p`
  color: ${(props) => props.theme.greyMedium};
  margin-bottom: 3px;
  line-height: 1.2;
`;

const NewsTitle = styled.h3`
  line-height: 1.2;
  font-weight: normal;

  a {
    text-decoration: none;
  }
`;

const NewsTeacher = styled.p`
  color: ${(props) => props.theme.greyMedium};
  margin-bottom: 3px;
  line-height: 1.2;
`;

const NewsDate = styled.p`
  margin-bottom: 3px;
  line-height: 1.2;
`;

const NewsTeaser = styled.p`
  color: ${(props) => props.theme.greyMedium};
`;

const NewsTags = styled.p`
  color: ${(props) => props.theme.greyMedium};
  line-height: 1;
  border-top: 1px solid ${(props) => props.theme.greyDark};
  padding-top: 5px;
`;

const NewsLink = styled.a`
  display: block;
  margin: 5px 0;
`;

const StyledButton = styled(Button)`
  margin: 1rem 0;
`;

const ArchiveLabel = styled.div`
  background-color: #aaa;
  margin: 1rem 0;
  padding: 0 15px;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  border-radius: 3px;
  font-weight: normal;
`;

const NewsItem = (props) => {
  const { t } = props;
  const tags = props.tags ? (
    <NewsTags>
      <small>{props.tags.join(", ")}</small>
    </NewsTags>
  ) : null;

  let link = null;

  if (props.mbo_link === "course") {
    link = (
      <StyledButton
        href="https://www.eversports.de/s/yogabee"
        target="_blank"
      >
        {t("registration")}
      </StyledButton>
    );
  } else if (props.mbo_link === "class") {
    link = (
      <StyledButton
        href="https://www.eversports.de/s/yogabee"
        target="_blank"
      >
        {t("registration")}
      </StyledButton>
    );
  } else if (props.mbo_link === "workshop") {
    link = (
      <StyledButton
        href="https://www.eversports.de/s/yogabee"
        target="_blank"
      >
        {t("registration")}
      </StyledButton>
    );
  } else if (props.mbo_link === "archive") {
    link = <ArchiveLabel>{t("archive")}</ArchiveLabel>;
  }

  return (
    <NewsContainer>
      <Link to={`/news/${props.link}`}>
        <NewsImage
          src={`${process.env.REACT_APP_ROOT}/${props.image}`}
          alt={props.name}
        />
      </Link>
      <NewsType>{props.type}</NewsType>
      <NewsTitle>
        <Link to={`/news/${props.link}`}>{props.title}</Link>
      </NewsTitle>
      <NewsTeacher>
        {t("with")} {props.teacher}
      </NewsTeacher>
      <NewsDate>
        <strong>
          {props.date} {props.time}
        </strong>
        <br />
      </NewsDate>

      {link}

      <NewsTeaser>
        <span dangerouslySetInnerHTML={{ __html: props.teaser }}></span>
      </NewsTeaser>
    </NewsContainer>
  );
};

export default translate(NewsItem);
