import React from 'react';
import Page from '../components/Page';
import { translate, getLanguage } from 'react-switch-lang';
import styled from 'styled-components';
import Teaser from '../components/Teaser';
import Schedule from '../components/Schedule';
import Accordeon from '../components/Accordeon';

// STYLES
const EverspotsWidget = styled.iframe`
  width: 100%;
  height: 380px;
  border: none;
  
  @media only screen and (min-width: ${props => props.theme.flexboxgrid.breakpoints.md}em) {
    height: 500px;
  }
`;

const ScheduleHeader = styled.h2`
  background-color: ${props => props.theme.color.body};
  color: ${props => props.theme.color.text.body};
  padding-bottom: 10px;
  line-height: 1;
  margin: 30px 0 20px 0;
`;

// COMPONENT
class OfferSchedule extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      accOpen: 1
    };
  }

  clickHandler = selector => {
    if (selector === this.state.accOpen) {
      this.setState({ accOpen: 0 });
    } else {
      this.setState({ accOpen: selector });
    }
  }

  render() {
    const { t, data } = this.props;
    const lang = getLanguage();
    const date = new Date();
    const day = date.getDay();

    return (
      <Page title={ t('nav.schedule')}>
        <h1>{ t('nav.schedule')}</h1>
        <Teaser className="teaser" dangerouslySetInnerHTML={{ __html: data[`intro_${lang}`] ? data[`intro_${lang}`] : data[`intro`] }}></Teaser>

        <div>
          {/* <Accordeon title={t('monday')} open={this.state.accOpen === 1} click={ () => this.clickHandler(1) }>
            <Schedule filter="weekday" by="Mo" data={this.props.schedules} layoutType="schedule" />
          </Accordeon>

          <Accordeon title={t('tuesday')} open={this.state.accOpen === 2} click={ () => this.clickHandler(2) }>
            <Schedule filter="weekday" by="Di" data={this.props.schedules} layoutType="schedule" />
          </Accordeon>

          <Accordeon title={t('wednesday')} open={this.state.accOpen === 3} click={ () => this.clickHandler(3) }>
            <Schedule filter="weekday" by="Mi" data={this.props.schedules} layoutType="schedule" />
          </Accordeon>

          <Accordeon title={t('thursday')} open={this.state.accOpen === 4} click={ () => this.clickHandler(4) }>
            <Schedule filter="weekday" by="Do" data={this.props.schedules} layoutType="schedule" />
          </Accordeon>

          <Accordeon title={t('friday')} open={this.state.accOpen === 5} click={ () => this.clickHandler(5) }>
            <Schedule filter="weekday" by="Fr" data={this.props.schedules} layoutType="schedule" />
          </Accordeon>

          <Accordeon title={t('saturday')} open={this.state.accOpen === 6} click={ () => this.clickHandler(6) }>
            <Schedule filter="weekday" by="Sa" data={this.props.schedules} layoutType="schedule" />
          </Accordeon>

          <Accordeon title={t('sunday')} open={this.state.accOpen === 7} click={ () => this.clickHandler(7) }>
            <Schedule filter="weekday" by="So" data={this.props.schedules} layoutType="schedule" />
          </Accordeon> */}
          <EverspotsWidget src="https://www.eversports.de/widget/w/8um9im" frameborder="0"></EverspotsWidget>
          <ScheduleHeader>{ t('our_courses')}</ScheduleHeader>
          <Accordeon title={t('hatha_yoga')} open={this.state.accOpen === 1} click={ () => this.clickHandler(1) }>
            <Schedule filter="category" by="hartha" data={this.props.schedules} layoutType="schedule" />
          </Accordeon>
          <Accordeon title={t('ashtanga_yoga')} open={this.state.accOpen === 2} click={ () => this.clickHandler(2) }>
            <Schedule filter="category" by="ashtanga" data={this.props.schedules} layoutType="schedule" />
          </Accordeon>
          <Accordeon title={t('pregnant_yoga')} open={this.state.accOpen === 3} click={ () => this.clickHandler(3) }>
            <Schedule filter="category" by="schwangere" data={this.props.schedules} layoutType="schedule" />
          </Accordeon>
          <Accordeon title={t('post_natal_yoga')} open={this.state.accOpen === 4} click={ () => this.clickHandler(4) }>
            <Schedule filter="category" by="geburt" data={this.props.schedules} layoutType="schedule" />
          </Accordeon>
          {/* <Accordeon title={t('workshops')} open={this.state.accOpen === 5} click={ () => this.clickHandler(5) }>
            <Schedule filter="category" by="workshops" data={this.props.schedules} layoutType="schedule" />
          </Accordeon> */}
        </div>
      </Page>
    );
  }
}
  
export default translate(OfferSchedule);