import React from 'react';
import { translate, getLanguage } from 'react-switch-lang';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'react-styled-flexboxgrid';
import Page from '../components/Page';
import FigCaption from '../components/FigCaption';
import PrevNext from '../components/PrevNext';
import Button from '../components/Button';

const NewsLink = styled.a`
  display: block;
  margin: 5px 0;
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  margin: 1rem 0;
`;

class NewsDetail extends React.Component {
  state = {
    news: null,
    currentIndex: null,
    currentNews: null,
  };

  componentWillMount() {
    const news = this.props.news.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }

      return 0;
    });

    const index = news.findIndex(
      (elem) => elem.title_slug === this.props.match.params.slug
    );

    this.setState({
      news: news,
      currentIndex: index,
      currentNews: news[index],
    });
  }

  prevHandler = () => {
    const index =
      this.state.currentIndex > 0
        ? this.state.currentIndex - 1
        : this.state.news.length - 1;

    this.setState((prevState) => {
      return {
        currentIndex: index,
        currentNews: prevState.news[index],
      };
    });
  };

  nextHandler = () => {
    const index =
      this.state.currentIndex === this.state.news.length - 1
        ? 0
        : this.state.currentIndex + 1;

    this.setState((prevState) => {
      return {
        currentIndex: index,
        currentNews: prevState.news[index],
      };
    });
  };

  render() {
    const lang = getLanguage();
    const { currentNews } = this.state;
    const { t } = this.props;

    const prevNews =
      this.state.currentIndex === 0
        ? this.state.news[this.state.news.length - 1][`title_${lang}`]
          ? this.state.news[this.state.news.length - 1][`title_${lang}`]
          : this.state.news[this.state.news.length - 1][`title`]
        : this.state.news[this.state.currentIndex - 1][`title_${lang}`]
        ? this.state.news[this.state.currentIndex - 1][`title_${lang}`]
        : this.state.news[this.state.currentIndex - 1][`title`];

    const nextNews =
      this.state.currentIndex === this.state.news.length - 1
        ? this.state.news[0][`title_${lang}`]
          ? this.state.news[0][`title_${lang}`]
          : this.state.news[0][`title`]
        : this.state.news[this.state.currentIndex + 1][`title_${lang}`]
        ? this.state.news[this.state.currentIndex + 1][`title_${lang}`]
        : this.state.news[this.state.currentIndex + 1][`title`];

    let link = null;

    if (this.state.currentNews.mbo_link === 'course') {
      link = (
        <StyledButton
          href="https://www.eversports.de/s/yogabee"
          target="_blank"
        >
          {t('registration')}
        </StyledButton>
      );
    } else if (this.state.currentNews.mbo_link === 'class') {
      link = (
        <StyledButton
          href="https://www.eversports.de/s/yogabee"
          target="_blank"
        >
          {t('registration')}
        </StyledButton>
      );
    } else if (this.state.currentNews.mbo_link === 'workshop') {
      link = (
        <StyledButton
          href="https://www.eversports.de/s/yogabee"
          target="_blank"
        >
          {t('registration')}
        </StyledButton>
      );
    }

    let teacher = '';

    console.log(currentNews.teacher);

    if (currentNews.teacher) {
      teacher = currentNews.teacher.name;
    } else if (currentNews.teacher_custom) {
      teacher = currentNews.teacher_custom;
    }

    return (
      <Page
        title={
          currentNews[`title_${lang}`]
            ? currentNews[`title_${lang}`]
            : currentNews[`title`]
        }
      >
        <PrevNext
          actualTitle={
            this.state.currentNews[`title_${lang}`]
              ? this.state.currentNews[`title_${lang}`]
              : this.state.currentNews[`title`]
          }
          prev={this.prevHandler}
          next={this.nextHandler}
          prevTitle={prevNews}
          nextTitle={nextNews}
        />

        <Row className="mb-section">
          <Col xs={12} sm={3} md={3}>
            <FigCaption
              imgSrc={`${process.env.REACT_APP_ROOT}/${currentNews.image.path}`}
              imgAlt={
                currentNews[`title_${lang}`]
                  ? currentNews[`title_${lang}`]
                  : currentNews[`title`]
              }
            />
          </Col>
          <Col xs={12} sm={9} md={6}>
            <div>{t(currentNews.type)}</div>
            <div>
              <small>
                {t('with')} {teacher}
              </small>
            </div>
            {/* <div><small>{currentNews.date} {currentNews.time}</small></div> */}
            <div>
              <small>
                {currentNews[`date_${lang}`]
                  ? currentNews[`date_${lang}`]
                  : currentNews[`date`]}{' '}
                {currentNews[`time_${lang}`]
                  ? currentNews[`time_${lang}`]
                  : currentNews[`time`]}
              </small>
            </div>

            {link}
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.currentNews[`text_${lang}`]
                  ? this.state.currentNews[`text_${lang}`]
                  : this.state.currentNews[`text`],
              }}
            ></div>
          </Col>
        </Row>
      </Page>
    );
  }
}

export default translate(NewsDetail);
