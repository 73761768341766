import React from 'react';
import Page from '../components/Page';
import { translate, getLanguage } from 'react-switch-lang';
import styled from 'styled-components';
import Accordeon from '../components/Accordeon';
import Teaser from '../components/Teaser';

// STYLES
const FaqText = styled.div`
  padding: 10px 0;
`;

// COMPONENT
class InfoFaq extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      accOpen: 0
    }
  }

  clickHandler = selector => {
    if (selector === this.state.accOpen) {
      this.setState({ accOpen: 0 });
    } else {
      this.setState({ accOpen: selector });
    }
  }

  render() {
    const { t, data } = this.props;
    const lang = getLanguage();

    let faqLang = 'faq';

    if (data[`faq_${lang}`] && data[`faq_${lang}`].length > 0) {
      faqLang = `faq_${lang}`;
    }

    return (
      <Page title={ t('nav.faq')}>
        <h1>{ t('nav.faq')}</h1>
        <Teaser className="teaser" dangerouslySetInnerHTML={{ __html: this.props.data[`intro_${lang}`] ? this.props.data[`intro_${lang}`] : this.props.data[`intro`] }}></Teaser>

        {data[faqLang].map((item, index) => (
          <Accordeon faq title={item.value.question} open={this.state.accOpen === index + 1} click={ () => this.clickHandler(index + 1) }>
            <FaqText dangerouslySetInnerHTML={{ __html: item.value.answer }}></FaqText>
          </Accordeon>
        ))}
      </Page>
    );
  }
}
  
export default translate(InfoFaq);