import React from 'react';
import Page from '../components/Page';
import { translate, getLanguage } from 'react-switch-lang';
import styled, { css } from 'styled-components';
import Teaser from '../components/Teaser';
import { Col, Row } from 'react-styled-flexboxgrid';
import Accordeon from '../components/Accordeon';
import NotificationCheckIn from '../components/NotificationCheckIn';
import Button from '../components/Button';

// STYLES
const AccordeonContainer = styled.div`
  margin-bottom: ${props => props.theme.layout.gap.large};
`;

const TableRow = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.text.high};
  padding: 8px 0;
  width: 100%;
`;

const Label = styled.div`
  font-weight: bold;
  text-transform: uppercase;

  a {
    font-weight: normal;
    text-transform: none;
    text-decoration: underline;
  }

  ${props => props.price && css`
    text-align: right;
  `}

  a {
    text-decoration: none;
  }
`;

const Text = styled.div`
  flex: 1;
`;

const TableHeader = styled.div`
  padding-top: 10px;
  border-bottom: 1px solid ${props => props.theme.color.text.high};
`;

const PricingCardsHint = styled.div`
  margin-top: 30px;
`;

const Hint = styled.div`
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  margin-bottom: ${props => props.theme.layout.gap.large};
`;

const PriceButton = styled.a`
  font-family: ${props => props.theme.font.family.primary};
  background-color: ${props => props.theme.color.button.surface};
  color: ${props => props.theme.color.button.inlineDark};
  border-radius: 3px;
  text-decoration: none;
  padding: 0 15px;
  font-weight: normal;
  display: inline-block;
  border: none;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
`;

// COMPONENT
class OfferPrices extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      accOpen: 1
    };
  }

  clickHandler = selector => {
    if (selector === this.state.accOpen) {
      this.setState({ accOpen: 0 });
    } else {
      this.setState({ accOpen: selector });
    }
  }

  render() {
    const { t, data, notification } = this.props;
    const lang = getLanguage();
    const newCustomersLang = data[`new_customers_${lang}`] ? `new_customers_${lang}` : 'new_customers';
    const singleCardsLang = data[`single_cards_${lang}`] ? `single_cards_${lang}` : 'single_cards';
    const pointsCardsLang = data[`points_cards_${lang}`] ? `points_cards_${lang}` : 'points_cards';
    const coursesLang = data[`courses_${lang}`] ? `courses_${lang}` : 'courses';

    return (
      <Page title={ t('nav.prices')}>
        <h1>{ t('nav.prices')}</h1>
        <Teaser className="teaser" dangerouslySetInnerHTML={{ __html: data[`intro_${lang}`] ? data[`intro_${lang}`] : data[`intro`] }}></Teaser>

        <StyledButton large href={ data.price_link }>{t('buy_now')}</StyledButton>

        <AccordeonContainer>
          <Accordeon title={t('new_customers')} open={this.state.accOpen === 1} click={ () => this.clickHandler(1) }>
            {data[newCustomersLang].map(item => (
              <TableRow>
                <Row>
                  <Col xs={12} md={3}><Label name><PriceButton href={item.value.link} target="_blank" rel="noopener noreferrer">{item.value.label}</PriceButton></Label></Col>
                  <Col xs={12} md={7}><Text dangerouslySetInnerHTML={{ __html: item.value.description }}></Text></Col>
                  <Col xs={12} md={2}><Label price>{item.value.price}</Label></Col>
                </Row>
              </TableRow>
            ))}
            {/* <Row>
              <Col xs={12}>
                <Hint><NotificationCheckIn text={notification[`notification_text_${lang}`] ? notification[`notification_text_${lang}`] : notification[`notification_text`]} /></Hint>
              </Col>
            </Row> */}
          </Accordeon>

          <Accordeon title={t('single_cards')} open={this.state.accOpen === 2} click={ () => this.clickHandler(2) }>
            {data[singleCardsLang].map(item => (
              <TableRow>
                <Row>
                  <Col xs={12} md={3}><Label name><PriceButton href={item.value.link} target="_blank" rel="noopener noreferrer">{item.value.label}</PriceButton></Label></Col>
                  <Col xs={12} md={7}><Text dangerouslySetInnerHTML={{ __html: item.value.description }}></Text></Col>
                  <Col xs={12} md={2}><Label price>{item.value.price}</Label></Col>
                </Row>
              </TableRow>
            ))}
          </Accordeon>

          <Accordeon title={t('points_cards')} open={this.state.accOpen === 3} click={ () => this.clickHandler(3) }>
            <TableHeader>
              <Row>
                <Col xs={3} xsOffset={3} md={3} mdOffset={3}>
                  <strong>{t('speedy')}</strong><br />
                  <span>{t('valid_2')}</span>
                </Col>
                <Col xs={3} md={3}>
                  <strong>{t('default')}</strong><br />
                  <span>{t('valid_5')}</span>
                </Col>
                <Col xs={3} md={3}>
                  <strong>{t('reduced')}</strong><br />
                  <span>{t('valid_5')}</span>
                </Col>
              </Row>
            </TableHeader>
            {data[pointsCardsLang].map(item => (
              <TableRow>
                <Row>
                  <Col xs={3} md={3}><Label name><span href={item.value.link} target="_blank" rel="noopener noreferrer">{item.value.label}</span></Label></Col>
                  <Col xs={3} md={3}><Label><PriceButton href={item.value.link_speedy} target="_blank" rel="noopener noreferrer">{item.value.price_speedy}</PriceButton></Label></Col>
                  <Col xs={3} md={3}><Label><PriceButton href={item.value.link_standard} target="_blank" rel="noopener noreferrer">{item.value.price_default}</PriceButton></Label></Col>
                  <Col xs={3} md={3}><Label><PriceButton href={item.value.link_special} target="_blank" rel="noopener noreferrer">{item.value.price_reduced}</PriceButton></Label></Col>
                </Row>
              </TableRow>
            ))}

            <PricingCardsHint dangerouslySetInnerHTML={{ __html: data[`points_cards_hint_${lang}`] ? data[`points_cards_hint_${lang}`] : data[`points_cards_hint`] }}></PricingCardsHint>
          </Accordeon>

          <Accordeon title={t('courses')} open={this.state.accOpen === 4} click={ () => this.clickHandler(4) }>
            {data[coursesLang].map(item => (
              <TableRow>
                <Row>
                  <Col xs={6} md={6}><Label name><PriceButton href={item.value.link} target="_blank" rel="noopener noreferrer">{item.value.label}</PriceButton></Label></Col>
                  <Col xs={6} md={6}><Label price>{item.value.price}</Label></Col>
                </Row>
              </TableRow>
            ))}
            <Hint id="info" className="" dangerouslySetInnerHTML={{ __html: data[`text_${lang}`] ? data[`text_${lang}`] : data[`text`] }}></Hint>
          </Accordeon>

          <Accordeon title={t('coupons')} open={this.state.accOpen === 5} click={ () => this.clickHandler(5) }>
            <Hint dangerouslySetInnerHTML={{ __html: data[`coupons_text_${lang}`] ? data[`coupons_text_${lang}`] : data[`coupons_text`] }}></Hint>
          </Accordeon>
        </AccordeonContainer>

        <StyledButton large href={ data.coupon_link }>{t('gift_certificate')}</StyledButton>

        <NotificationCheckIn 
          headline={notification[`notification_headline_${lang}`] ? notification[`notification_headline_${lang}`] : notification[`notification_headline`]} 
          text={notification[`notification_text_${lang}`] ? notification[`notification_text_${lang}`] : notification[`notification_text`]} />
      </Page>
    );
  }
}
  
export default translate(OfferPrices);