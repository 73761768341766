import React from 'react';
import { ThemeProvider } from 'styled-components';
import Theme from '../styles/Theme';
import GlobalStyles from '../styles/Global';

const Layout = props => (
  <ThemeProvider theme={Theme}>
    <GlobalStyles />
    <div className="fadeIn">{ props.children }</div>
  </ThemeProvider>
);

export default Layout;